/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "injective_exchange_rpc";
function createBaseGetTxRequest() {
    return { hash: "" };
}
export const GetTxRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.hash !== "") {
            writer.uint32(10).string(message.hash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetTxRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.hash = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { hash: isSet(object.hash) ? String(object.hash) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.hash !== undefined && (obj.hash = message.hash);
        return obj;
    },
    create(base) {
        return GetTxRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a;
        const message = createBaseGetTxRequest();
        message.hash = (_a = object.hash) !== null && _a !== void 0 ? _a : "";
        return message;
    },
};
function createBaseGetTxResponse() {
    return {
        txHash: "",
        height: "0",
        index: 0,
        codespace: "",
        code: 0,
        data: new Uint8Array(),
        rawLog: "",
        timestamp: "",
    };
}
export const GetTxResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.txHash !== "") {
            writer.uint32(10).string(message.txHash);
        }
        if (message.height !== "0") {
            writer.uint32(16).sint64(message.height);
        }
        if (message.index !== 0) {
            writer.uint32(24).uint32(message.index);
        }
        if (message.codespace !== "") {
            writer.uint32(34).string(message.codespace);
        }
        if (message.code !== 0) {
            writer.uint32(40).uint32(message.code);
        }
        if (message.data.length !== 0) {
            writer.uint32(50).bytes(message.data);
        }
        if (message.rawLog !== "") {
            writer.uint32(58).string(message.rawLog);
        }
        if (message.timestamp !== "") {
            writer.uint32(66).string(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetTxResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.txHash = reader.string();
                    break;
                case 2:
                    message.height = longToString(reader.sint64());
                    break;
                case 3:
                    message.index = reader.uint32();
                    break;
                case 4:
                    message.codespace = reader.string();
                    break;
                case 5:
                    message.code = reader.uint32();
                    break;
                case 6:
                    message.data = reader.bytes();
                    break;
                case 7:
                    message.rawLog = reader.string();
                    break;
                case 8:
                    message.timestamp = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            height: isSet(object.height) ? String(object.height) : "0",
            index: isSet(object.index) ? Number(object.index) : 0,
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(),
            rawLog: isSet(object.rawLog) ? String(object.rawLog) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.height !== undefined && (obj.height = message.height);
        message.index !== undefined && (obj.index = Math.round(message.index));
        message.codespace !== undefined && (obj.codespace = message.codespace);
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.data !== undefined &&
            (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()));
        message.rawLog !== undefined && (obj.rawLog = message.rawLog);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return GetTxResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const message = createBaseGetTxResponse();
        message.txHash = (_a = object.txHash) !== null && _a !== void 0 ? _a : "";
        message.height = (_b = object.height) !== null && _b !== void 0 ? _b : "0";
        message.index = (_c = object.index) !== null && _c !== void 0 ? _c : 0;
        message.codespace = (_d = object.codespace) !== null && _d !== void 0 ? _d : "";
        message.code = (_e = object.code) !== null && _e !== void 0 ? _e : 0;
        message.data = (_f = object.data) !== null && _f !== void 0 ? _f : new Uint8Array();
        message.rawLog = (_g = object.rawLog) !== null && _g !== void 0 ? _g : "";
        message.timestamp = (_h = object.timestamp) !== null && _h !== void 0 ? _h : "";
        return message;
    },
};
function createBasePrepareTxRequest() {
    return { chainId: "0", signerAddress: "", sequence: "0", memo: "", timeoutHeight: "0", fee: undefined, msgs: [] };
}
export const PrepareTxRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.chainId !== "0") {
            writer.uint32(8).uint64(message.chainId);
        }
        if (message.signerAddress !== "") {
            writer.uint32(18).string(message.signerAddress);
        }
        if (message.sequence !== "0") {
            writer.uint32(24).uint64(message.sequence);
        }
        if (message.memo !== "") {
            writer.uint32(34).string(message.memo);
        }
        if (message.timeoutHeight !== "0") {
            writer.uint32(40).uint64(message.timeoutHeight);
        }
        if (message.fee !== undefined) {
            CosmosTxFee.encode(message.fee, writer.uint32(50).fork()).ldelim();
        }
        for (const v of message.msgs) {
            writer.uint32(58).bytes(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePrepareTxRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = longToString(reader.uint64());
                    break;
                case 2:
                    message.signerAddress = reader.string();
                    break;
                case 3:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 4:
                    message.memo = reader.string();
                    break;
                case 5:
                    message.timeoutHeight = longToString(reader.uint64());
                    break;
                case 6:
                    message.fee = CosmosTxFee.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.msgs.push(reader.bytes());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            chainId: isSet(object.chainId) ? String(object.chainId) : "0",
            signerAddress: isSet(object.signerAddress) ? String(object.signerAddress) : "",
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            memo: isSet(object.memo) ? String(object.memo) : "",
            timeoutHeight: isSet(object.timeoutHeight) ? String(object.timeoutHeight) : "0",
            fee: isSet(object.fee) ? CosmosTxFee.fromJSON(object.fee) : undefined,
            msgs: Array.isArray(object === null || object === void 0 ? void 0 : object.msgs) ? object.msgs.map((e) => bytesFromBase64(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.signerAddress !== undefined && (obj.signerAddress = message.signerAddress);
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.memo !== undefined && (obj.memo = message.memo);
        message.timeoutHeight !== undefined && (obj.timeoutHeight = message.timeoutHeight);
        message.fee !== undefined && (obj.fee = message.fee ? CosmosTxFee.toJSON(message.fee) : undefined);
        if (message.msgs) {
            obj.msgs = message.msgs.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.msgs = [];
        }
        return obj;
    },
    create(base) {
        return PrepareTxRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f;
        const message = createBasePrepareTxRequest();
        message.chainId = (_a = object.chainId) !== null && _a !== void 0 ? _a : "0";
        message.signerAddress = (_b = object.signerAddress) !== null && _b !== void 0 ? _b : "";
        message.sequence = (_c = object.sequence) !== null && _c !== void 0 ? _c : "0";
        message.memo = (_d = object.memo) !== null && _d !== void 0 ? _d : "";
        message.timeoutHeight = (_e = object.timeoutHeight) !== null && _e !== void 0 ? _e : "0";
        message.fee = (object.fee !== undefined && object.fee !== null) ? CosmosTxFee.fromPartial(object.fee) : undefined;
        message.msgs = ((_f = object.msgs) === null || _f === void 0 ? void 0 : _f.map((e) => e)) || [];
        return message;
    },
};
function createBaseCosmosTxFee() {
    return { price: [], gas: "0", delegateFee: false };
}
export const CosmosTxFee = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.price) {
            CosmosCoin.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.gas !== "0") {
            writer.uint32(16).uint64(message.gas);
        }
        if (message.delegateFee === true) {
            writer.uint32(24).bool(message.delegateFee);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCosmosTxFee();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price.push(CosmosCoin.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.gas = longToString(reader.uint64());
                    break;
                case 3:
                    message.delegateFee = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: Array.isArray(object === null || object === void 0 ? void 0 : object.price) ? object.price.map((e) => CosmosCoin.fromJSON(e)) : [],
            gas: isSet(object.gas) ? String(object.gas) : "0",
            delegateFee: isSet(object.delegateFee) ? Boolean(object.delegateFee) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.price) {
            obj.price = message.price.map((e) => e ? CosmosCoin.toJSON(e) : undefined);
        }
        else {
            obj.price = [];
        }
        message.gas !== undefined && (obj.gas = message.gas);
        message.delegateFee !== undefined && (obj.delegateFee = message.delegateFee);
        return obj;
    },
    create(base) {
        return CosmosTxFee.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c;
        const message = createBaseCosmosTxFee();
        message.price = ((_a = object.price) === null || _a === void 0 ? void 0 : _a.map((e) => CosmosCoin.fromPartial(e))) || [];
        message.gas = (_b = object.gas) !== null && _b !== void 0 ? _b : "0";
        message.delegateFee = (_c = object.delegateFee) !== null && _c !== void 0 ? _c : false;
        return message;
    },
};
function createBaseCosmosCoin() {
    return { denom: "", amount: "" };
}
export const CosmosCoin = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.denom !== "") {
            writer.uint32(10).string(message.denom);
        }
        if (message.amount !== "") {
            writer.uint32(18).string(message.amount);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCosmosCoin();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.denom = reader.string();
                    break;
                case 2:
                    message.amount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            denom: isSet(object.denom) ? String(object.denom) : "",
            amount: isSet(object.amount) ? String(object.amount) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.denom !== undefined && (obj.denom = message.denom);
        message.amount !== undefined && (obj.amount = message.amount);
        return obj;
    },
    create(base) {
        return CosmosCoin.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b;
        const message = createBaseCosmosCoin();
        message.denom = (_a = object.denom) !== null && _a !== void 0 ? _a : "";
        message.amount = (_b = object.amount) !== null && _b !== void 0 ? _b : "";
        return message;
    },
};
function createBasePrepareTxResponse() {
    return { data: "", sequence: "0", signMode: "", pubKeyType: "", feePayer: "", feePayerSig: "" };
}
export const PrepareTxResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.data !== "") {
            writer.uint32(10).string(message.data);
        }
        if (message.sequence !== "0") {
            writer.uint32(16).uint64(message.sequence);
        }
        if (message.signMode !== "") {
            writer.uint32(26).string(message.signMode);
        }
        if (message.pubKeyType !== "") {
            writer.uint32(34).string(message.pubKeyType);
        }
        if (message.feePayer !== "") {
            writer.uint32(42).string(message.feePayer);
        }
        if (message.feePayerSig !== "") {
            writer.uint32(50).string(message.feePayerSig);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePrepareTxResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.data = reader.string();
                    break;
                case 2:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 3:
                    message.signMode = reader.string();
                    break;
                case 4:
                    message.pubKeyType = reader.string();
                    break;
                case 5:
                    message.feePayer = reader.string();
                    break;
                case 6:
                    message.feePayerSig = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            data: isSet(object.data) ? String(object.data) : "",
            sequence: isSet(object.sequence) ? String(object.sequence) : "0",
            signMode: isSet(object.signMode) ? String(object.signMode) : "",
            pubKeyType: isSet(object.pubKeyType) ? String(object.pubKeyType) : "",
            feePayer: isSet(object.feePayer) ? String(object.feePayer) : "",
            feePayerSig: isSet(object.feePayerSig) ? String(object.feePayerSig) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.data !== undefined && (obj.data = message.data);
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.signMode !== undefined && (obj.signMode = message.signMode);
        message.pubKeyType !== undefined && (obj.pubKeyType = message.pubKeyType);
        message.feePayer !== undefined && (obj.feePayer = message.feePayer);
        message.feePayerSig !== undefined && (obj.feePayerSig = message.feePayerSig);
        return obj;
    },
    create(base) {
        return PrepareTxResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f;
        const message = createBasePrepareTxResponse();
        message.data = (_a = object.data) !== null && _a !== void 0 ? _a : "";
        message.sequence = (_b = object.sequence) !== null && _b !== void 0 ? _b : "0";
        message.signMode = (_c = object.signMode) !== null && _c !== void 0 ? _c : "";
        message.pubKeyType = (_d = object.pubKeyType) !== null && _d !== void 0 ? _d : "";
        message.feePayer = (_e = object.feePayer) !== null && _e !== void 0 ? _e : "";
        message.feePayerSig = (_f = object.feePayerSig) !== null && _f !== void 0 ? _f : "";
        return message;
    },
};
function createBaseBroadcastTxRequest() {
    return {
        chainId: "0",
        tx: new Uint8Array(),
        msgs: [],
        pubKey: undefined,
        signature: "",
        feePayer: "",
        feePayerSig: "",
        mode: "",
    };
}
export const BroadcastTxRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.chainId !== "0") {
            writer.uint32(8).uint64(message.chainId);
        }
        if (message.tx.length !== 0) {
            writer.uint32(18).bytes(message.tx);
        }
        for (const v of message.msgs) {
            writer.uint32(26).bytes(v);
        }
        if (message.pubKey !== undefined) {
            CosmosPubKey.encode(message.pubKey, writer.uint32(34).fork()).ldelim();
        }
        if (message.signature !== "") {
            writer.uint32(42).string(message.signature);
        }
        if (message.feePayer !== "") {
            writer.uint32(50).string(message.feePayer);
        }
        if (message.feePayerSig !== "") {
            writer.uint32(58).string(message.feePayerSig);
        }
        if (message.mode !== "") {
            writer.uint32(66).string(message.mode);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBroadcastTxRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = longToString(reader.uint64());
                    break;
                case 2:
                    message.tx = reader.bytes();
                    break;
                case 3:
                    message.msgs.push(reader.bytes());
                    break;
                case 4:
                    message.pubKey = CosmosPubKey.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.signature = reader.string();
                    break;
                case 6:
                    message.feePayer = reader.string();
                    break;
                case 7:
                    message.feePayerSig = reader.string();
                    break;
                case 8:
                    message.mode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            chainId: isSet(object.chainId) ? String(object.chainId) : "0",
            tx: isSet(object.tx) ? bytesFromBase64(object.tx) : new Uint8Array(),
            msgs: Array.isArray(object === null || object === void 0 ? void 0 : object.msgs) ? object.msgs.map((e) => bytesFromBase64(e)) : [],
            pubKey: isSet(object.pubKey) ? CosmosPubKey.fromJSON(object.pubKey) : undefined,
            signature: isSet(object.signature) ? String(object.signature) : "",
            feePayer: isSet(object.feePayer) ? String(object.feePayer) : "",
            feePayerSig: isSet(object.feePayerSig) ? String(object.feePayerSig) : "",
            mode: isSet(object.mode) ? String(object.mode) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.tx !== undefined && (obj.tx = base64FromBytes(message.tx !== undefined ? message.tx : new Uint8Array()));
        if (message.msgs) {
            obj.msgs = message.msgs.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.msgs = [];
        }
        message.pubKey !== undefined && (obj.pubKey = message.pubKey ? CosmosPubKey.toJSON(message.pubKey) : undefined);
        message.signature !== undefined && (obj.signature = message.signature);
        message.feePayer !== undefined && (obj.feePayer = message.feePayer);
        message.feePayerSig !== undefined && (obj.feePayerSig = message.feePayerSig);
        message.mode !== undefined && (obj.mode = message.mode);
        return obj;
    },
    create(base) {
        return BroadcastTxRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f, _g;
        const message = createBaseBroadcastTxRequest();
        message.chainId = (_a = object.chainId) !== null && _a !== void 0 ? _a : "0";
        message.tx = (_b = object.tx) !== null && _b !== void 0 ? _b : new Uint8Array();
        message.msgs = ((_c = object.msgs) === null || _c === void 0 ? void 0 : _c.map((e) => e)) || [];
        message.pubKey = (object.pubKey !== undefined && object.pubKey !== null)
            ? CosmosPubKey.fromPartial(object.pubKey)
            : undefined;
        message.signature = (_d = object.signature) !== null && _d !== void 0 ? _d : "";
        message.feePayer = (_e = object.feePayer) !== null && _e !== void 0 ? _e : "";
        message.feePayerSig = (_f = object.feePayerSig) !== null && _f !== void 0 ? _f : "";
        message.mode = (_g = object.mode) !== null && _g !== void 0 ? _g : "";
        return message;
    },
};
function createBaseCosmosPubKey() {
    return { type: "", key: "" };
}
export const CosmosPubKey = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== "") {
            writer.uint32(10).string(message.type);
        }
        if (message.key !== "") {
            writer.uint32(18).string(message.key);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCosmosPubKey();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { type: isSet(object.type) ? String(object.type) : "", key: isSet(object.key) ? String(object.key) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.type !== undefined && (obj.type = message.type);
        message.key !== undefined && (obj.key = message.key);
        return obj;
    },
    create(base) {
        return CosmosPubKey.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b;
        const message = createBaseCosmosPubKey();
        message.type = (_a = object.type) !== null && _a !== void 0 ? _a : "";
        message.key = (_b = object.key) !== null && _b !== void 0 ? _b : "";
        return message;
    },
};
function createBaseBroadcastTxResponse() {
    return {
        txHash: "",
        height: "0",
        index: 0,
        codespace: "",
        code: 0,
        data: new Uint8Array(),
        rawLog: "",
        timestamp: "",
    };
}
export const BroadcastTxResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.txHash !== "") {
            writer.uint32(10).string(message.txHash);
        }
        if (message.height !== "0") {
            writer.uint32(16).sint64(message.height);
        }
        if (message.index !== 0) {
            writer.uint32(24).uint32(message.index);
        }
        if (message.codespace !== "") {
            writer.uint32(34).string(message.codespace);
        }
        if (message.code !== 0) {
            writer.uint32(40).uint32(message.code);
        }
        if (message.data.length !== 0) {
            writer.uint32(50).bytes(message.data);
        }
        if (message.rawLog !== "") {
            writer.uint32(58).string(message.rawLog);
        }
        if (message.timestamp !== "") {
            writer.uint32(66).string(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBroadcastTxResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.txHash = reader.string();
                    break;
                case 2:
                    message.height = longToString(reader.sint64());
                    break;
                case 3:
                    message.index = reader.uint32();
                    break;
                case 4:
                    message.codespace = reader.string();
                    break;
                case 5:
                    message.code = reader.uint32();
                    break;
                case 6:
                    message.data = reader.bytes();
                    break;
                case 7:
                    message.rawLog = reader.string();
                    break;
                case 8:
                    message.timestamp = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            height: isSet(object.height) ? String(object.height) : "0",
            index: isSet(object.index) ? Number(object.index) : 0,
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(),
            rawLog: isSet(object.rawLog) ? String(object.rawLog) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.height !== undefined && (obj.height = message.height);
        message.index !== undefined && (obj.index = Math.round(message.index));
        message.codespace !== undefined && (obj.codespace = message.codespace);
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.data !== undefined &&
            (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()));
        message.rawLog !== undefined && (obj.rawLog = message.rawLog);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return BroadcastTxResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const message = createBaseBroadcastTxResponse();
        message.txHash = (_a = object.txHash) !== null && _a !== void 0 ? _a : "";
        message.height = (_b = object.height) !== null && _b !== void 0 ? _b : "0";
        message.index = (_c = object.index) !== null && _c !== void 0 ? _c : 0;
        message.codespace = (_d = object.codespace) !== null && _d !== void 0 ? _d : "";
        message.code = (_e = object.code) !== null && _e !== void 0 ? _e : 0;
        message.data = (_f = object.data) !== null && _f !== void 0 ? _f : new Uint8Array();
        message.rawLog = (_g = object.rawLog) !== null && _g !== void 0 ? _g : "";
        message.timestamp = (_h = object.timestamp) !== null && _h !== void 0 ? _h : "";
        return message;
    },
};
function createBasePrepareCosmosTxRequest() {
    return { chainId: "0", senderAddress: "", memo: "", timeoutHeight: "0", fee: undefined, msgs: [] };
}
export const PrepareCosmosTxRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.chainId !== "0") {
            writer.uint32(8).uint64(message.chainId);
        }
        if (message.senderAddress !== "") {
            writer.uint32(18).string(message.senderAddress);
        }
        if (message.memo !== "") {
            writer.uint32(26).string(message.memo);
        }
        if (message.timeoutHeight !== "0") {
            writer.uint32(32).uint64(message.timeoutHeight);
        }
        if (message.fee !== undefined) {
            CosmosTxFee.encode(message.fee, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.msgs) {
            writer.uint32(50).bytes(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePrepareCosmosTxRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = longToString(reader.uint64());
                    break;
                case 2:
                    message.senderAddress = reader.string();
                    break;
                case 3:
                    message.memo = reader.string();
                    break;
                case 4:
                    message.timeoutHeight = longToString(reader.uint64());
                    break;
                case 5:
                    message.fee = CosmosTxFee.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.msgs.push(reader.bytes());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            chainId: isSet(object.chainId) ? String(object.chainId) : "0",
            senderAddress: isSet(object.senderAddress) ? String(object.senderAddress) : "",
            memo: isSet(object.memo) ? String(object.memo) : "",
            timeoutHeight: isSet(object.timeoutHeight) ? String(object.timeoutHeight) : "0",
            fee: isSet(object.fee) ? CosmosTxFee.fromJSON(object.fee) : undefined,
            msgs: Array.isArray(object === null || object === void 0 ? void 0 : object.msgs) ? object.msgs.map((e) => bytesFromBase64(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.senderAddress !== undefined && (obj.senderAddress = message.senderAddress);
        message.memo !== undefined && (obj.memo = message.memo);
        message.timeoutHeight !== undefined && (obj.timeoutHeight = message.timeoutHeight);
        message.fee !== undefined && (obj.fee = message.fee ? CosmosTxFee.toJSON(message.fee) : undefined);
        if (message.msgs) {
            obj.msgs = message.msgs.map((e) => base64FromBytes(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.msgs = [];
        }
        return obj;
    },
    create(base) {
        return PrepareCosmosTxRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e;
        const message = createBasePrepareCosmosTxRequest();
        message.chainId = (_a = object.chainId) !== null && _a !== void 0 ? _a : "0";
        message.senderAddress = (_b = object.senderAddress) !== null && _b !== void 0 ? _b : "";
        message.memo = (_c = object.memo) !== null && _c !== void 0 ? _c : "";
        message.timeoutHeight = (_d = object.timeoutHeight) !== null && _d !== void 0 ? _d : "0";
        message.fee = (object.fee !== undefined && object.fee !== null) ? CosmosTxFee.fromPartial(object.fee) : undefined;
        message.msgs = ((_e = object.msgs) === null || _e === void 0 ? void 0 : _e.map((e) => e)) || [];
        return message;
    },
};
function createBasePrepareCosmosTxResponse() {
    return {
        tx: new Uint8Array(),
        signMode: "",
        pubKeyType: "",
        feePayer: "",
        feePayerSig: "",
        feePayerPubKey: undefined,
    };
}
export const PrepareCosmosTxResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tx.length !== 0) {
            writer.uint32(10).bytes(message.tx);
        }
        if (message.signMode !== "") {
            writer.uint32(18).string(message.signMode);
        }
        if (message.pubKeyType !== "") {
            writer.uint32(26).string(message.pubKeyType);
        }
        if (message.feePayer !== "") {
            writer.uint32(34).string(message.feePayer);
        }
        if (message.feePayerSig !== "") {
            writer.uint32(42).string(message.feePayerSig);
        }
        if (message.feePayerPubKey !== undefined) {
            CosmosPubKey.encode(message.feePayerPubKey, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePrepareCosmosTxResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tx = reader.bytes();
                    break;
                case 2:
                    message.signMode = reader.string();
                    break;
                case 3:
                    message.pubKeyType = reader.string();
                    break;
                case 4:
                    message.feePayer = reader.string();
                    break;
                case 5:
                    message.feePayerSig = reader.string();
                    break;
                case 6:
                    message.feePayerPubKey = CosmosPubKey.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tx: isSet(object.tx) ? bytesFromBase64(object.tx) : new Uint8Array(),
            signMode: isSet(object.signMode) ? String(object.signMode) : "",
            pubKeyType: isSet(object.pubKeyType) ? String(object.pubKeyType) : "",
            feePayer: isSet(object.feePayer) ? String(object.feePayer) : "",
            feePayerSig: isSet(object.feePayerSig) ? String(object.feePayerSig) : "",
            feePayerPubKey: isSet(object.feePayerPubKey) ? CosmosPubKey.fromJSON(object.feePayerPubKey) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.tx !== undefined && (obj.tx = base64FromBytes(message.tx !== undefined ? message.tx : new Uint8Array()));
        message.signMode !== undefined && (obj.signMode = message.signMode);
        message.pubKeyType !== undefined && (obj.pubKeyType = message.pubKeyType);
        message.feePayer !== undefined && (obj.feePayer = message.feePayer);
        message.feePayerSig !== undefined && (obj.feePayerSig = message.feePayerSig);
        message.feePayerPubKey !== undefined &&
            (obj.feePayerPubKey = message.feePayerPubKey ? CosmosPubKey.toJSON(message.feePayerPubKey) : undefined);
        return obj;
    },
    create(base) {
        return PrepareCosmosTxResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e;
        const message = createBasePrepareCosmosTxResponse();
        message.tx = (_a = object.tx) !== null && _a !== void 0 ? _a : new Uint8Array();
        message.signMode = (_b = object.signMode) !== null && _b !== void 0 ? _b : "";
        message.pubKeyType = (_c = object.pubKeyType) !== null && _c !== void 0 ? _c : "";
        message.feePayer = (_d = object.feePayer) !== null && _d !== void 0 ? _d : "";
        message.feePayerSig = (_e = object.feePayerSig) !== null && _e !== void 0 ? _e : "";
        message.feePayerPubKey = (object.feePayerPubKey !== undefined && object.feePayerPubKey !== null)
            ? CosmosPubKey.fromPartial(object.feePayerPubKey)
            : undefined;
        return message;
    },
};
function createBaseBroadcastCosmosTxRequest() {
    return { tx: new Uint8Array(), pubKey: undefined, signature: "", senderAddress: "" };
}
export const BroadcastCosmosTxRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tx.length !== 0) {
            writer.uint32(10).bytes(message.tx);
        }
        if (message.pubKey !== undefined) {
            CosmosPubKey.encode(message.pubKey, writer.uint32(18).fork()).ldelim();
        }
        if (message.signature !== "") {
            writer.uint32(26).string(message.signature);
        }
        if (message.senderAddress !== "") {
            writer.uint32(34).string(message.senderAddress);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBroadcastCosmosTxRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tx = reader.bytes();
                    break;
                case 2:
                    message.pubKey = CosmosPubKey.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.signature = reader.string();
                    break;
                case 4:
                    message.senderAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            tx: isSet(object.tx) ? bytesFromBase64(object.tx) : new Uint8Array(),
            pubKey: isSet(object.pubKey) ? CosmosPubKey.fromJSON(object.pubKey) : undefined,
            signature: isSet(object.signature) ? String(object.signature) : "",
            senderAddress: isSet(object.senderAddress) ? String(object.senderAddress) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.tx !== undefined && (obj.tx = base64FromBytes(message.tx !== undefined ? message.tx : new Uint8Array()));
        message.pubKey !== undefined && (obj.pubKey = message.pubKey ? CosmosPubKey.toJSON(message.pubKey) : undefined);
        message.signature !== undefined && (obj.signature = message.signature);
        message.senderAddress !== undefined && (obj.senderAddress = message.senderAddress);
        return obj;
    },
    create(base) {
        return BroadcastCosmosTxRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c;
        const message = createBaseBroadcastCosmosTxRequest();
        message.tx = (_a = object.tx) !== null && _a !== void 0 ? _a : new Uint8Array();
        message.pubKey = (object.pubKey !== undefined && object.pubKey !== null)
            ? CosmosPubKey.fromPartial(object.pubKey)
            : undefined;
        message.signature = (_b = object.signature) !== null && _b !== void 0 ? _b : "";
        message.senderAddress = (_c = object.senderAddress) !== null && _c !== void 0 ? _c : "";
        return message;
    },
};
function createBaseBroadcastCosmosTxResponse() {
    return {
        txHash: "",
        height: "0",
        index: 0,
        codespace: "",
        code: 0,
        data: new Uint8Array(),
        rawLog: "",
        timestamp: "",
    };
}
export const BroadcastCosmosTxResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.txHash !== "") {
            writer.uint32(10).string(message.txHash);
        }
        if (message.height !== "0") {
            writer.uint32(16).sint64(message.height);
        }
        if (message.index !== 0) {
            writer.uint32(24).uint32(message.index);
        }
        if (message.codespace !== "") {
            writer.uint32(34).string(message.codespace);
        }
        if (message.code !== 0) {
            writer.uint32(40).uint32(message.code);
        }
        if (message.data.length !== 0) {
            writer.uint32(50).bytes(message.data);
        }
        if (message.rawLog !== "") {
            writer.uint32(58).string(message.rawLog);
        }
        if (message.timestamp !== "") {
            writer.uint32(66).string(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBroadcastCosmosTxResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.txHash = reader.string();
                    break;
                case 2:
                    message.height = longToString(reader.sint64());
                    break;
                case 3:
                    message.index = reader.uint32();
                    break;
                case 4:
                    message.codespace = reader.string();
                    break;
                case 5:
                    message.code = reader.uint32();
                    break;
                case 6:
                    message.data = reader.bytes();
                    break;
                case 7:
                    message.rawLog = reader.string();
                    break;
                case 8:
                    message.timestamp = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            txHash: isSet(object.txHash) ? String(object.txHash) : "",
            height: isSet(object.height) ? String(object.height) : "0",
            index: isSet(object.index) ? Number(object.index) : 0,
            codespace: isSet(object.codespace) ? String(object.codespace) : "",
            code: isSet(object.code) ? Number(object.code) : 0,
            data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(),
            rawLog: isSet(object.rawLog) ? String(object.rawLog) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.txHash !== undefined && (obj.txHash = message.txHash);
        message.height !== undefined && (obj.height = message.height);
        message.index !== undefined && (obj.index = Math.round(message.index));
        message.codespace !== undefined && (obj.codespace = message.codespace);
        message.code !== undefined && (obj.code = Math.round(message.code));
        message.data !== undefined &&
            (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()));
        message.rawLog !== undefined && (obj.rawLog = message.rawLog);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return BroadcastCosmosTxResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const message = createBaseBroadcastCosmosTxResponse();
        message.txHash = (_a = object.txHash) !== null && _a !== void 0 ? _a : "";
        message.height = (_b = object.height) !== null && _b !== void 0 ? _b : "0";
        message.index = (_c = object.index) !== null && _c !== void 0 ? _c : 0;
        message.codespace = (_d = object.codespace) !== null && _d !== void 0 ? _d : "";
        message.code = (_e = object.code) !== null && _e !== void 0 ? _e : 0;
        message.data = (_f = object.data) !== null && _f !== void 0 ? _f : new Uint8Array();
        message.rawLog = (_g = object.rawLog) !== null && _g !== void 0 ? _g : "";
        message.timestamp = (_h = object.timestamp) !== null && _h !== void 0 ? _h : "";
        return message;
    },
};
function createBaseGetFeePayerRequest() {
    return {};
}
export const GetFeePayerRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetFeePayerRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return GetFeePayerRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(_) {
        const message = createBaseGetFeePayerRequest();
        return message;
    },
};
function createBaseGetFeePayerResponse() {
    return { feePayer: "", feePayerPubKey: undefined };
}
export const GetFeePayerResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.feePayer !== "") {
            writer.uint32(10).string(message.feePayer);
        }
        if (message.feePayerPubKey !== undefined) {
            CosmosPubKey.encode(message.feePayerPubKey, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetFeePayerResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.feePayer = reader.string();
                    break;
                case 2:
                    message.feePayerPubKey = CosmosPubKey.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            feePayer: isSet(object.feePayer) ? String(object.feePayer) : "",
            feePayerPubKey: isSet(object.feePayerPubKey) ? CosmosPubKey.fromJSON(object.feePayerPubKey) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.feePayer !== undefined && (obj.feePayer = message.feePayer);
        message.feePayerPubKey !== undefined &&
            (obj.feePayerPubKey = message.feePayerPubKey ? CosmosPubKey.toJSON(message.feePayerPubKey) : undefined);
        return obj;
    },
    create(base) {
        return GetFeePayerResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a;
        const message = createBaseGetFeePayerResponse();
        message.feePayer = (_a = object.feePayer) !== null && _a !== void 0 ? _a : "";
        message.feePayerPubKey = (object.feePayerPubKey !== undefined && object.feePayerPubKey !== null)
            ? CosmosPubKey.fromPartial(object.feePayerPubKey)
            : undefined;
        return message;
    },
};
export class InjectiveExchangeRPCClientImpl {
    constructor(rpc) {
        this.rpc = rpc;
        this.GetTx = this.GetTx.bind(this);
        this.PrepareTx = this.PrepareTx.bind(this);
        this.BroadcastTx = this.BroadcastTx.bind(this);
        this.PrepareCosmosTx = this.PrepareCosmosTx.bind(this);
        this.BroadcastCosmosTx = this.BroadcastCosmosTx.bind(this);
        this.GetFeePayer = this.GetFeePayer.bind(this);
    }
    GetTx(request, metadata) {
        return this.rpc.unary(InjectiveExchangeRPCGetTxDesc, GetTxRequest.fromPartial(request), metadata);
    }
    PrepareTx(request, metadata) {
        return this.rpc.unary(InjectiveExchangeRPCPrepareTxDesc, PrepareTxRequest.fromPartial(request), metadata);
    }
    BroadcastTx(request, metadata) {
        return this.rpc.unary(InjectiveExchangeRPCBroadcastTxDesc, BroadcastTxRequest.fromPartial(request), metadata);
    }
    PrepareCosmosTx(request, metadata) {
        return this.rpc.unary(InjectiveExchangeRPCPrepareCosmosTxDesc, PrepareCosmosTxRequest.fromPartial(request), metadata);
    }
    BroadcastCosmosTx(request, metadata) {
        return this.rpc.unary(InjectiveExchangeRPCBroadcastCosmosTxDesc, BroadcastCosmosTxRequest.fromPartial(request), metadata);
    }
    GetFeePayer(request, metadata) {
        return this.rpc.unary(InjectiveExchangeRPCGetFeePayerDesc, GetFeePayerRequest.fromPartial(request), metadata);
    }
}
export const InjectiveExchangeRPCDesc = { serviceName: "injective_exchange_rpc.InjectiveExchangeRPC" };
export const InjectiveExchangeRPCGetTxDesc = {
    methodName: "GetTx",
    service: InjectiveExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetTxRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetTxResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export const InjectiveExchangeRPCPrepareTxDesc = {
    methodName: "PrepareTx",
    service: InjectiveExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PrepareTxRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PrepareTxResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export const InjectiveExchangeRPCBroadcastTxDesc = {
    methodName: "BroadcastTx",
    service: InjectiveExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return BroadcastTxRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = BroadcastTxResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export const InjectiveExchangeRPCPrepareCosmosTxDesc = {
    methodName: "PrepareCosmosTx",
    service: InjectiveExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PrepareCosmosTxRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PrepareCosmosTxResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export const InjectiveExchangeRPCBroadcastCosmosTxDesc = {
    methodName: "BroadcastCosmosTx",
    service: InjectiveExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return BroadcastCosmosTxRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = BroadcastCosmosTxResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export const InjectiveExchangeRPCGetFeePayerDesc = {
    methodName: "GetFeePayer",
    service: InjectiveExchangeRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetFeePayerRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = GetFeePayerResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export class GrpcWebImpl {
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        var _a;
        const request = Object.assign(Object.assign({}, _request), methodDesc.requestType);
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders(Object.assign(Object.assign({}, (_a = this.options) === null || _a === void 0 ? void 0 : _a.metadata.headersMap), metadata === null || metadata === void 0 ? void 0 : metadata.headersMap))
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function bytesFromBase64(b64) {
    if (tsProtoGlobalThis.Buffer) {
        return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
    }
    else {
        const bin = tsProtoGlobalThis.atob(b64);
        const arr = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; ++i) {
            arr[i] = bin.charCodeAt(i);
        }
        return arr;
    }
}
function base64FromBytes(arr) {
    if (tsProtoGlobalThis.Buffer) {
        return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
    }
    else {
        const bin = [];
        arr.forEach((byte) => {
            bin.push(String.fromCharCode(byte));
        });
        return tsProtoGlobalThis.btoa(bin.join(""));
    }
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
