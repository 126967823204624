// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.21.3
// source: stride/stakeibc/validator.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "stride.stakeibc";

export interface Validator {
  name: string;
  address: string;
  weight: string;
  delegation: string;
  slashQueryProgressTracker: string;
  slashQueryCheckpoint: string;
  sharesToTokensRate: string;
  delegationChangesInProgress: string;
  slashQueryInProgress: boolean;
}

function createBaseValidator(): Validator {
  return {
    name: "",
    address: "",
    weight: "0",
    delegation: "",
    slashQueryProgressTracker: "",
    slashQueryCheckpoint: "",
    sharesToTokensRate: "",
    delegationChangesInProgress: "0",
    slashQueryInProgress: false,
  };
}

export const Validator = {
  encode(
    message: Validator,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    if (message.weight !== "0") {
      writer.uint32(48).uint64(message.weight);
    }
    if (message.delegation !== "") {
      writer.uint32(42).string(message.delegation);
    }
    if (message.slashQueryProgressTracker !== "") {
      writer.uint32(74).string(message.slashQueryProgressTracker);
    }
    if (message.slashQueryCheckpoint !== "") {
      writer.uint32(98).string(message.slashQueryCheckpoint);
    }
    if (message.sharesToTokensRate !== "") {
      writer.uint32(82).string(message.sharesToTokensRate);
    }
    if (message.delegationChangesInProgress !== "0") {
      writer.uint32(88).int64(message.delegationChangesInProgress);
    }
    if (message.slashQueryInProgress !== false) {
      writer.uint32(104).bool(message.slashQueryInProgress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Validator {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.weight = longToString(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.delegation = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.slashQueryProgressTracker = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.slashQueryCheckpoint = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.sharesToTokensRate = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.delegationChangesInProgress = longToString(
            reader.int64() as Long
          );
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.slashQueryInProgress = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Validator {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      weight: isSet(object.weight) ? globalThis.String(object.weight) : "0",
      delegation: isSet(object.delegation)
        ? globalThis.String(object.delegation)
        : "",
      slashQueryProgressTracker: isSet(object.slashQueryProgressTracker)
        ? globalThis.String(object.slashQueryProgressTracker)
        : "",
      slashQueryCheckpoint: isSet(object.slashQueryCheckpoint)
        ? globalThis.String(object.slashQueryCheckpoint)
        : "",
      sharesToTokensRate: isSet(object.sharesToTokensRate)
        ? globalThis.String(object.sharesToTokensRate)
        : "",
      delegationChangesInProgress: isSet(object.delegationChangesInProgress)
        ? globalThis.String(object.delegationChangesInProgress)
        : "0",
      slashQueryInProgress: isSet(object.slashQueryInProgress)
        ? globalThis.Boolean(object.slashQueryInProgress)
        : false,
    };
  },

  toJSON(message: Validator): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.weight !== "0") {
      obj.weight = message.weight;
    }
    if (message.delegation !== "") {
      obj.delegation = message.delegation;
    }
    if (message.slashQueryProgressTracker !== "") {
      obj.slashQueryProgressTracker = message.slashQueryProgressTracker;
    }
    if (message.slashQueryCheckpoint !== "") {
      obj.slashQueryCheckpoint = message.slashQueryCheckpoint;
    }
    if (message.sharesToTokensRate !== "") {
      obj.sharesToTokensRate = message.sharesToTokensRate;
    }
    if (message.delegationChangesInProgress !== "0") {
      obj.delegationChangesInProgress = message.delegationChangesInProgress;
    }
    if (message.slashQueryInProgress !== false) {
      obj.slashQueryInProgress = message.slashQueryInProgress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Validator>, I>>(base?: I): Validator {
    return Validator.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Validator>, I>>(
    object: I
  ): Validator {
    const message = createBaseValidator();
    message.name = object.name ?? "";
    message.address = object.address ?? "";
    message.weight = object.weight ?? "0";
    message.delegation = object.delegation ?? "";
    message.slashQueryProgressTracker = object.slashQueryProgressTracker ?? "";
    message.slashQueryCheckpoint = object.slashQueryCheckpoint ?? "";
    message.sharesToTokensRate = object.sharesToTokensRate ?? "";
    message.delegationChangesInProgress =
      object.delegationChangesInProgress ?? "0";
    message.slashQueryInProgress = object.slashQueryInProgress ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
