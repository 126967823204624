// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.21.3
// source: stride/staketia/tx.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Coin } from "../../cosmos/base/v1beta1/coin";
import {
  DelegationRecord,
  RedemptionRecord,
  UnbondingRecord,
} from "./staketia";

export const protobufPackage = "stride.staketia";

export enum OverwritableRecordType {
  RECORD_TYPE_DELEGATION = 0,
  RECORD_TYPE_UNBONDING = 1,
  RECORD_TYPE_REDEMPTION = 2,
  UNRECOGNIZED = -1,
}

export function overwritableRecordTypeFromJSON(
  object: any
): OverwritableRecordType {
  switch (object) {
    case 0:
    case "RECORD_TYPE_DELEGATION":
      return OverwritableRecordType.RECORD_TYPE_DELEGATION;
    case 1:
    case "RECORD_TYPE_UNBONDING":
      return OverwritableRecordType.RECORD_TYPE_UNBONDING;
    case 2:
    case "RECORD_TYPE_REDEMPTION":
      return OverwritableRecordType.RECORD_TYPE_REDEMPTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OverwritableRecordType.UNRECOGNIZED;
  }
}

export function overwritableRecordTypeToJSON(
  object: OverwritableRecordType
): string {
  switch (object) {
    case OverwritableRecordType.RECORD_TYPE_DELEGATION:
      return "RECORD_TYPE_DELEGATION";
    case OverwritableRecordType.RECORD_TYPE_UNBONDING:
      return "RECORD_TYPE_UNBONDING";
    case OverwritableRecordType.RECORD_TYPE_REDEMPTION:
      return "RECORD_TYPE_REDEMPTION";
    case OverwritableRecordType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** LiquidStake */
export interface MsgLiquidStake {
  staker: string;
  nativeAmount: string;
}

export interface MsgLiquidStakeResponse {
  stToken: Coin | undefined;
}

/** RedeemStake */
export interface MsgRedeemStake {
  redeemer: string;
  stTokenAmount: string;
}

export interface MsgRedeemStakeResponse {
  nativeToken: Coin | undefined;
}

/** ConfirmDelegation */
export interface MsgConfirmDelegation {
  operator: string;
  recordId: string;
  txHash: string;
}

export interface MsgConfirmDelegationResponse {}

/** ConfirmUndelegation */
export interface MsgConfirmUndelegation {
  operator: string;
  recordId: string;
  txHash: string;
}

export interface MsgConfirmUndelegationResponse {}

/** ConfirmUnbondedTokenSweep */
export interface MsgConfirmUnbondedTokenSweep {
  operator: string;
  recordId: string;
  txHash: string;
}

export interface MsgConfirmUnbondedTokenSweepResponse {}

/** AdjustDelegatedBalance */
export interface MsgAdjustDelegatedBalance {
  operator: string;
  delegationOffset: string;
  validatorAddress: string;
}

export interface MsgAdjustDelegatedBalanceResponse {}

/** UpdateInnerRedemptionRate */
export interface MsgUpdateInnerRedemptionRateBounds {
  creator: string;
  minInnerRedemptionRate: string;
  maxInnerRedemptionRate: string;
}

export interface MsgUpdateInnerRedemptionRateBoundsResponse {}

/** ResumeHostZone */
export interface MsgResumeHostZone {
  creator: string;
}

export interface MsgResumeHostZoneResponse {}

/** RefreshRedemptionRate */
export interface MsgRefreshRedemptionRate {
  creator: string;
}

export interface MsgRefreshRedemptionRateResponse {}

/** OverwriteDelegationRecord */
export interface MsgOverwriteDelegationRecord {
  creator: string;
  delegationRecord: DelegationRecord | undefined;
}

export interface MsgOverwriteDelegationRecordResponse {}

/** OverwriteUnbondingRecord */
export interface MsgOverwriteUnbondingRecord {
  creator: string;
  unbondingRecord: UnbondingRecord | undefined;
}

export interface MsgOverwriteUnbondingRecordResponse {}

/** OverwriteRedemptionRecord */
export interface MsgOverwriteRedemptionRecord {
  creator: string;
  redemptionRecord: RedemptionRecord | undefined;
}

export interface MsgOverwriteRedemptionRecordResponse {}

/** SetOperatorAddress */
export interface MsgSetOperatorAddress {
  signer: string;
  operator: string;
}

export interface MsgSetOperatorAddressResponse {}

function createBaseMsgLiquidStake(): MsgLiquidStake {
  return { staker: "", nativeAmount: "" };
}

export const MsgLiquidStake = {
  encode(
    message: MsgLiquidStake,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.staker !== "") {
      writer.uint32(10).string(message.staker);
    }
    if (message.nativeAmount !== "") {
      writer.uint32(18).string(message.nativeAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgLiquidStake {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgLiquidStake();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staker = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nativeAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgLiquidStake {
    return {
      staker: isSet(object.staker) ? globalThis.String(object.staker) : "",
      nativeAmount: isSet(object.nativeAmount)
        ? globalThis.String(object.nativeAmount)
        : "",
    };
  },

  toJSON(message: MsgLiquidStake): unknown {
    const obj: any = {};
    if (message.staker !== "") {
      obj.staker = message.staker;
    }
    if (message.nativeAmount !== "") {
      obj.nativeAmount = message.nativeAmount;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgLiquidStake>, I>>(
    base?: I
  ): MsgLiquidStake {
    return MsgLiquidStake.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgLiquidStake>, I>>(
    object: I
  ): MsgLiquidStake {
    const message = createBaseMsgLiquidStake();
    message.staker = object.staker ?? "";
    message.nativeAmount = object.nativeAmount ?? "";
    return message;
  },
};

function createBaseMsgLiquidStakeResponse(): MsgLiquidStakeResponse {
  return { stToken: undefined };
}

export const MsgLiquidStakeResponse = {
  encode(
    message: MsgLiquidStakeResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.stToken !== undefined) {
      Coin.encode(message.stToken, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgLiquidStakeResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgLiquidStakeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.stToken = Coin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgLiquidStakeResponse {
    return {
      stToken: isSet(object.stToken)
        ? Coin.fromJSON(object.stToken)
        : undefined,
    };
  },

  toJSON(message: MsgLiquidStakeResponse): unknown {
    const obj: any = {};
    if (message.stToken !== undefined) {
      obj.stToken = Coin.toJSON(message.stToken);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgLiquidStakeResponse>, I>>(
    base?: I
  ): MsgLiquidStakeResponse {
    return MsgLiquidStakeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgLiquidStakeResponse>, I>>(
    object: I
  ): MsgLiquidStakeResponse {
    const message = createBaseMsgLiquidStakeResponse();
    message.stToken =
      object.stToken !== undefined && object.stToken !== null
        ? Coin.fromPartial(object.stToken)
        : undefined;
    return message;
  },
};

function createBaseMsgRedeemStake(): MsgRedeemStake {
  return { redeemer: "", stTokenAmount: "" };
}

export const MsgRedeemStake = {
  encode(
    message: MsgRedeemStake,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.redeemer !== "") {
      writer.uint32(10).string(message.redeemer);
    }
    if (message.stTokenAmount !== "") {
      writer.uint32(18).string(message.stTokenAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgRedeemStake {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgRedeemStake();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.redeemer = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stTokenAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgRedeemStake {
    return {
      redeemer: isSet(object.redeemer)
        ? globalThis.String(object.redeemer)
        : "",
      stTokenAmount: isSet(object.stTokenAmount)
        ? globalThis.String(object.stTokenAmount)
        : "",
    };
  },

  toJSON(message: MsgRedeemStake): unknown {
    const obj: any = {};
    if (message.redeemer !== "") {
      obj.redeemer = message.redeemer;
    }
    if (message.stTokenAmount !== "") {
      obj.stTokenAmount = message.stTokenAmount;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgRedeemStake>, I>>(
    base?: I
  ): MsgRedeemStake {
    return MsgRedeemStake.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgRedeemStake>, I>>(
    object: I
  ): MsgRedeemStake {
    const message = createBaseMsgRedeemStake();
    message.redeemer = object.redeemer ?? "";
    message.stTokenAmount = object.stTokenAmount ?? "";
    return message;
  },
};

function createBaseMsgRedeemStakeResponse(): MsgRedeemStakeResponse {
  return { nativeToken: undefined };
}

export const MsgRedeemStakeResponse = {
  encode(
    message: MsgRedeemStakeResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.nativeToken !== undefined) {
      Coin.encode(message.nativeToken, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgRedeemStakeResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgRedeemStakeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nativeToken = Coin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgRedeemStakeResponse {
    return {
      nativeToken: isSet(object.nativeToken)
        ? Coin.fromJSON(object.nativeToken)
        : undefined,
    };
  },

  toJSON(message: MsgRedeemStakeResponse): unknown {
    const obj: any = {};
    if (message.nativeToken !== undefined) {
      obj.nativeToken = Coin.toJSON(message.nativeToken);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgRedeemStakeResponse>, I>>(
    base?: I
  ): MsgRedeemStakeResponse {
    return MsgRedeemStakeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgRedeemStakeResponse>, I>>(
    object: I
  ): MsgRedeemStakeResponse {
    const message = createBaseMsgRedeemStakeResponse();
    message.nativeToken =
      object.nativeToken !== undefined && object.nativeToken !== null
        ? Coin.fromPartial(object.nativeToken)
        : undefined;
    return message;
  },
};

function createBaseMsgConfirmDelegation(): MsgConfirmDelegation {
  return { operator: "", recordId: "0", txHash: "" };
}

export const MsgConfirmDelegation = {
  encode(
    message: MsgConfirmDelegation,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.operator !== "") {
      writer.uint32(10).string(message.operator);
    }
    if (message.recordId !== "0") {
      writer.uint32(16).uint64(message.recordId);
    }
    if (message.txHash !== "") {
      writer.uint32(26).string(message.txHash);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgConfirmDelegation {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgConfirmDelegation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.operator = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.recordId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.txHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgConfirmDelegation {
    return {
      operator: isSet(object.operator)
        ? globalThis.String(object.operator)
        : "",
      recordId: isSet(object.recordId)
        ? globalThis.String(object.recordId)
        : "0",
      txHash: isSet(object.txHash) ? globalThis.String(object.txHash) : "",
    };
  },

  toJSON(message: MsgConfirmDelegation): unknown {
    const obj: any = {};
    if (message.operator !== "") {
      obj.operator = message.operator;
    }
    if (message.recordId !== "0") {
      obj.recordId = message.recordId;
    }
    if (message.txHash !== "") {
      obj.txHash = message.txHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgConfirmDelegation>, I>>(
    base?: I
  ): MsgConfirmDelegation {
    return MsgConfirmDelegation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgConfirmDelegation>, I>>(
    object: I
  ): MsgConfirmDelegation {
    const message = createBaseMsgConfirmDelegation();
    message.operator = object.operator ?? "";
    message.recordId = object.recordId ?? "0";
    message.txHash = object.txHash ?? "";
    return message;
  },
};

function createBaseMsgConfirmDelegationResponse(): MsgConfirmDelegationResponse {
  return {};
}

export const MsgConfirmDelegationResponse = {
  encode(
    _: MsgConfirmDelegationResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgConfirmDelegationResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgConfirmDelegationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgConfirmDelegationResponse {
    return {};
  },

  toJSON(_: MsgConfirmDelegationResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgConfirmDelegationResponse>, I>>(
    base?: I
  ): MsgConfirmDelegationResponse {
    return MsgConfirmDelegationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgConfirmDelegationResponse>, I>>(
    _: I
  ): MsgConfirmDelegationResponse {
    const message = createBaseMsgConfirmDelegationResponse();
    return message;
  },
};

function createBaseMsgConfirmUndelegation(): MsgConfirmUndelegation {
  return { operator: "", recordId: "0", txHash: "" };
}

export const MsgConfirmUndelegation = {
  encode(
    message: MsgConfirmUndelegation,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.operator !== "") {
      writer.uint32(10).string(message.operator);
    }
    if (message.recordId !== "0") {
      writer.uint32(16).uint64(message.recordId);
    }
    if (message.txHash !== "") {
      writer.uint32(26).string(message.txHash);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgConfirmUndelegation {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgConfirmUndelegation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.operator = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.recordId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.txHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgConfirmUndelegation {
    return {
      operator: isSet(object.operator)
        ? globalThis.String(object.operator)
        : "",
      recordId: isSet(object.recordId)
        ? globalThis.String(object.recordId)
        : "0",
      txHash: isSet(object.txHash) ? globalThis.String(object.txHash) : "",
    };
  },

  toJSON(message: MsgConfirmUndelegation): unknown {
    const obj: any = {};
    if (message.operator !== "") {
      obj.operator = message.operator;
    }
    if (message.recordId !== "0") {
      obj.recordId = message.recordId;
    }
    if (message.txHash !== "") {
      obj.txHash = message.txHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgConfirmUndelegation>, I>>(
    base?: I
  ): MsgConfirmUndelegation {
    return MsgConfirmUndelegation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgConfirmUndelegation>, I>>(
    object: I
  ): MsgConfirmUndelegation {
    const message = createBaseMsgConfirmUndelegation();
    message.operator = object.operator ?? "";
    message.recordId = object.recordId ?? "0";
    message.txHash = object.txHash ?? "";
    return message;
  },
};

function createBaseMsgConfirmUndelegationResponse(): MsgConfirmUndelegationResponse {
  return {};
}

export const MsgConfirmUndelegationResponse = {
  encode(
    _: MsgConfirmUndelegationResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgConfirmUndelegationResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgConfirmUndelegationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgConfirmUndelegationResponse {
    return {};
  },

  toJSON(_: MsgConfirmUndelegationResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgConfirmUndelegationResponse>, I>>(
    base?: I
  ): MsgConfirmUndelegationResponse {
    return MsgConfirmUndelegationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgConfirmUndelegationResponse>, I>>(
    _: I
  ): MsgConfirmUndelegationResponse {
    const message = createBaseMsgConfirmUndelegationResponse();
    return message;
  },
};

function createBaseMsgConfirmUnbondedTokenSweep(): MsgConfirmUnbondedTokenSweep {
  return { operator: "", recordId: "0", txHash: "" };
}

export const MsgConfirmUnbondedTokenSweep = {
  encode(
    message: MsgConfirmUnbondedTokenSweep,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.operator !== "") {
      writer.uint32(10).string(message.operator);
    }
    if (message.recordId !== "0") {
      writer.uint32(16).uint64(message.recordId);
    }
    if (message.txHash !== "") {
      writer.uint32(26).string(message.txHash);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgConfirmUnbondedTokenSweep {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgConfirmUnbondedTokenSweep();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.operator = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.recordId = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.txHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgConfirmUnbondedTokenSweep {
    return {
      operator: isSet(object.operator)
        ? globalThis.String(object.operator)
        : "",
      recordId: isSet(object.recordId)
        ? globalThis.String(object.recordId)
        : "0",
      txHash: isSet(object.txHash) ? globalThis.String(object.txHash) : "",
    };
  },

  toJSON(message: MsgConfirmUnbondedTokenSweep): unknown {
    const obj: any = {};
    if (message.operator !== "") {
      obj.operator = message.operator;
    }
    if (message.recordId !== "0") {
      obj.recordId = message.recordId;
    }
    if (message.txHash !== "") {
      obj.txHash = message.txHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgConfirmUnbondedTokenSweep>, I>>(
    base?: I
  ): MsgConfirmUnbondedTokenSweep {
    return MsgConfirmUnbondedTokenSweep.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgConfirmUnbondedTokenSweep>, I>>(
    object: I
  ): MsgConfirmUnbondedTokenSweep {
    const message = createBaseMsgConfirmUnbondedTokenSweep();
    message.operator = object.operator ?? "";
    message.recordId = object.recordId ?? "0";
    message.txHash = object.txHash ?? "";
    return message;
  },
};

function createBaseMsgConfirmUnbondedTokenSweepResponse(): MsgConfirmUnbondedTokenSweepResponse {
  return {};
}

export const MsgConfirmUnbondedTokenSweepResponse = {
  encode(
    _: MsgConfirmUnbondedTokenSweepResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgConfirmUnbondedTokenSweepResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgConfirmUnbondedTokenSweepResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgConfirmUnbondedTokenSweepResponse {
    return {};
  },

  toJSON(_: MsgConfirmUnbondedTokenSweepResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgConfirmUnbondedTokenSweepResponse>, I>>(
    base?: I
  ): MsgConfirmUnbondedTokenSweepResponse {
    return MsgConfirmUnbondedTokenSweepResponse.fromPartial(
      base ?? ({} as any)
    );
  },
  fromPartial<
    I extends Exact<DeepPartial<MsgConfirmUnbondedTokenSweepResponse>, I>
  >(_: I): MsgConfirmUnbondedTokenSweepResponse {
    const message = createBaseMsgConfirmUnbondedTokenSweepResponse();
    return message;
  },
};

function createBaseMsgAdjustDelegatedBalance(): MsgAdjustDelegatedBalance {
  return { operator: "", delegationOffset: "", validatorAddress: "" };
}

export const MsgAdjustDelegatedBalance = {
  encode(
    message: MsgAdjustDelegatedBalance,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.operator !== "") {
      writer.uint32(10).string(message.operator);
    }
    if (message.delegationOffset !== "") {
      writer.uint32(18).string(message.delegationOffset);
    }
    if (message.validatorAddress !== "") {
      writer.uint32(26).string(message.validatorAddress);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgAdjustDelegatedBalance {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgAdjustDelegatedBalance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.operator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.delegationOffset = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.validatorAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgAdjustDelegatedBalance {
    return {
      operator: isSet(object.operator)
        ? globalThis.String(object.operator)
        : "",
      delegationOffset: isSet(object.delegationOffset)
        ? globalThis.String(object.delegationOffset)
        : "",
      validatorAddress: isSet(object.validatorAddress)
        ? globalThis.String(object.validatorAddress)
        : "",
    };
  },

  toJSON(message: MsgAdjustDelegatedBalance): unknown {
    const obj: any = {};
    if (message.operator !== "") {
      obj.operator = message.operator;
    }
    if (message.delegationOffset !== "") {
      obj.delegationOffset = message.delegationOffset;
    }
    if (message.validatorAddress !== "") {
      obj.validatorAddress = message.validatorAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgAdjustDelegatedBalance>, I>>(
    base?: I
  ): MsgAdjustDelegatedBalance {
    return MsgAdjustDelegatedBalance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgAdjustDelegatedBalance>, I>>(
    object: I
  ): MsgAdjustDelegatedBalance {
    const message = createBaseMsgAdjustDelegatedBalance();
    message.operator = object.operator ?? "";
    message.delegationOffset = object.delegationOffset ?? "";
    message.validatorAddress = object.validatorAddress ?? "";
    return message;
  },
};

function createBaseMsgAdjustDelegatedBalanceResponse(): MsgAdjustDelegatedBalanceResponse {
  return {};
}

export const MsgAdjustDelegatedBalanceResponse = {
  encode(
    _: MsgAdjustDelegatedBalanceResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgAdjustDelegatedBalanceResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgAdjustDelegatedBalanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgAdjustDelegatedBalanceResponse {
    return {};
  },

  toJSON(_: MsgAdjustDelegatedBalanceResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgAdjustDelegatedBalanceResponse>, I>>(
    base?: I
  ): MsgAdjustDelegatedBalanceResponse {
    return MsgAdjustDelegatedBalanceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<MsgAdjustDelegatedBalanceResponse>, I>
  >(_: I): MsgAdjustDelegatedBalanceResponse {
    const message = createBaseMsgAdjustDelegatedBalanceResponse();
    return message;
  },
};

function createBaseMsgUpdateInnerRedemptionRateBounds(): MsgUpdateInnerRedemptionRateBounds {
  return {
    creator: "",
    minInnerRedemptionRate: "",
    maxInnerRedemptionRate: "",
  };
}

export const MsgUpdateInnerRedemptionRateBounds = {
  encode(
    message: MsgUpdateInnerRedemptionRateBounds,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.minInnerRedemptionRate !== "") {
      writer.uint32(18).string(message.minInnerRedemptionRate);
    }
    if (message.maxInnerRedemptionRate !== "") {
      writer.uint32(26).string(message.maxInnerRedemptionRate);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgUpdateInnerRedemptionRateBounds {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgUpdateInnerRedemptionRateBounds();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.minInnerRedemptionRate = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maxInnerRedemptionRate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgUpdateInnerRedemptionRateBounds {
    return {
      creator: isSet(object.creator) ? globalThis.String(object.creator) : "",
      minInnerRedemptionRate: isSet(object.minInnerRedemptionRate)
        ? globalThis.String(object.minInnerRedemptionRate)
        : "",
      maxInnerRedemptionRate: isSet(object.maxInnerRedemptionRate)
        ? globalThis.String(object.maxInnerRedemptionRate)
        : "",
    };
  },

  toJSON(message: MsgUpdateInnerRedemptionRateBounds): unknown {
    const obj: any = {};
    if (message.creator !== "") {
      obj.creator = message.creator;
    }
    if (message.minInnerRedemptionRate !== "") {
      obj.minInnerRedemptionRate = message.minInnerRedemptionRate;
    }
    if (message.maxInnerRedemptionRate !== "") {
      obj.maxInnerRedemptionRate = message.maxInnerRedemptionRate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgUpdateInnerRedemptionRateBounds>, I>>(
    base?: I
  ): MsgUpdateInnerRedemptionRateBounds {
    return MsgUpdateInnerRedemptionRateBounds.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<MsgUpdateInnerRedemptionRateBounds>, I>
  >(object: I): MsgUpdateInnerRedemptionRateBounds {
    const message = createBaseMsgUpdateInnerRedemptionRateBounds();
    message.creator = object.creator ?? "";
    message.minInnerRedemptionRate = object.minInnerRedemptionRate ?? "";
    message.maxInnerRedemptionRate = object.maxInnerRedemptionRate ?? "";
    return message;
  },
};

function createBaseMsgUpdateInnerRedemptionRateBoundsResponse(): MsgUpdateInnerRedemptionRateBoundsResponse {
  return {};
}

export const MsgUpdateInnerRedemptionRateBoundsResponse = {
  encode(
    _: MsgUpdateInnerRedemptionRateBoundsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgUpdateInnerRedemptionRateBoundsResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgUpdateInnerRedemptionRateBoundsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgUpdateInnerRedemptionRateBoundsResponse {
    return {};
  },

  toJSON(_: MsgUpdateInnerRedemptionRateBoundsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<
    I extends Exact<DeepPartial<MsgUpdateInnerRedemptionRateBoundsResponse>, I>
  >(base?: I): MsgUpdateInnerRedemptionRateBoundsResponse {
    return MsgUpdateInnerRedemptionRateBoundsResponse.fromPartial(
      base ?? ({} as any)
    );
  },
  fromPartial<
    I extends Exact<DeepPartial<MsgUpdateInnerRedemptionRateBoundsResponse>, I>
  >(_: I): MsgUpdateInnerRedemptionRateBoundsResponse {
    const message = createBaseMsgUpdateInnerRedemptionRateBoundsResponse();
    return message;
  },
};

function createBaseMsgResumeHostZone(): MsgResumeHostZone {
  return { creator: "" };
}

export const MsgResumeHostZone = {
  encode(
    message: MsgResumeHostZone,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgResumeHostZone {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgResumeHostZone();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgResumeHostZone {
    return {
      creator: isSet(object.creator) ? globalThis.String(object.creator) : "",
    };
  },

  toJSON(message: MsgResumeHostZone): unknown {
    const obj: any = {};
    if (message.creator !== "") {
      obj.creator = message.creator;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgResumeHostZone>, I>>(
    base?: I
  ): MsgResumeHostZone {
    return MsgResumeHostZone.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgResumeHostZone>, I>>(
    object: I
  ): MsgResumeHostZone {
    const message = createBaseMsgResumeHostZone();
    message.creator = object.creator ?? "";
    return message;
  },
};

function createBaseMsgResumeHostZoneResponse(): MsgResumeHostZoneResponse {
  return {};
}

export const MsgResumeHostZoneResponse = {
  encode(
    _: MsgResumeHostZoneResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgResumeHostZoneResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgResumeHostZoneResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgResumeHostZoneResponse {
    return {};
  },

  toJSON(_: MsgResumeHostZoneResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgResumeHostZoneResponse>, I>>(
    base?: I
  ): MsgResumeHostZoneResponse {
    return MsgResumeHostZoneResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgResumeHostZoneResponse>, I>>(
    _: I
  ): MsgResumeHostZoneResponse {
    const message = createBaseMsgResumeHostZoneResponse();
    return message;
  },
};

function createBaseMsgRefreshRedemptionRate(): MsgRefreshRedemptionRate {
  return { creator: "" };
}

export const MsgRefreshRedemptionRate = {
  encode(
    message: MsgRefreshRedemptionRate,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgRefreshRedemptionRate {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgRefreshRedemptionRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgRefreshRedemptionRate {
    return {
      creator: isSet(object.creator) ? globalThis.String(object.creator) : "",
    };
  },

  toJSON(message: MsgRefreshRedemptionRate): unknown {
    const obj: any = {};
    if (message.creator !== "") {
      obj.creator = message.creator;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgRefreshRedemptionRate>, I>>(
    base?: I
  ): MsgRefreshRedemptionRate {
    return MsgRefreshRedemptionRate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgRefreshRedemptionRate>, I>>(
    object: I
  ): MsgRefreshRedemptionRate {
    const message = createBaseMsgRefreshRedemptionRate();
    message.creator = object.creator ?? "";
    return message;
  },
};

function createBaseMsgRefreshRedemptionRateResponse(): MsgRefreshRedemptionRateResponse {
  return {};
}

export const MsgRefreshRedemptionRateResponse = {
  encode(
    _: MsgRefreshRedemptionRateResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgRefreshRedemptionRateResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgRefreshRedemptionRateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgRefreshRedemptionRateResponse {
    return {};
  },

  toJSON(_: MsgRefreshRedemptionRateResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgRefreshRedemptionRateResponse>, I>>(
    base?: I
  ): MsgRefreshRedemptionRateResponse {
    return MsgRefreshRedemptionRateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<MsgRefreshRedemptionRateResponse>, I>
  >(_: I): MsgRefreshRedemptionRateResponse {
    const message = createBaseMsgRefreshRedemptionRateResponse();
    return message;
  },
};

function createBaseMsgOverwriteDelegationRecord(): MsgOverwriteDelegationRecord {
  return { creator: "", delegationRecord: undefined };
}

export const MsgOverwriteDelegationRecord = {
  encode(
    message: MsgOverwriteDelegationRecord,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.delegationRecord !== undefined) {
      DelegationRecord.encode(
        message.delegationRecord,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgOverwriteDelegationRecord {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgOverwriteDelegationRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.delegationRecord = DelegationRecord.decode(
            reader,
            reader.uint32()
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgOverwriteDelegationRecord {
    return {
      creator: isSet(object.creator) ? globalThis.String(object.creator) : "",
      delegationRecord: isSet(object.delegationRecord)
        ? DelegationRecord.fromJSON(object.delegationRecord)
        : undefined,
    };
  },

  toJSON(message: MsgOverwriteDelegationRecord): unknown {
    const obj: any = {};
    if (message.creator !== "") {
      obj.creator = message.creator;
    }
    if (message.delegationRecord !== undefined) {
      obj.delegationRecord = DelegationRecord.toJSON(message.delegationRecord);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgOverwriteDelegationRecord>, I>>(
    base?: I
  ): MsgOverwriteDelegationRecord {
    return MsgOverwriteDelegationRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgOverwriteDelegationRecord>, I>>(
    object: I
  ): MsgOverwriteDelegationRecord {
    const message = createBaseMsgOverwriteDelegationRecord();
    message.creator = object.creator ?? "";
    message.delegationRecord =
      object.delegationRecord !== undefined && object.delegationRecord !== null
        ? DelegationRecord.fromPartial(object.delegationRecord)
        : undefined;
    return message;
  },
};

function createBaseMsgOverwriteDelegationRecordResponse(): MsgOverwriteDelegationRecordResponse {
  return {};
}

export const MsgOverwriteDelegationRecordResponse = {
  encode(
    _: MsgOverwriteDelegationRecordResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgOverwriteDelegationRecordResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgOverwriteDelegationRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgOverwriteDelegationRecordResponse {
    return {};
  },

  toJSON(_: MsgOverwriteDelegationRecordResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgOverwriteDelegationRecordResponse>, I>>(
    base?: I
  ): MsgOverwriteDelegationRecordResponse {
    return MsgOverwriteDelegationRecordResponse.fromPartial(
      base ?? ({} as any)
    );
  },
  fromPartial<
    I extends Exact<DeepPartial<MsgOverwriteDelegationRecordResponse>, I>
  >(_: I): MsgOverwriteDelegationRecordResponse {
    const message = createBaseMsgOverwriteDelegationRecordResponse();
    return message;
  },
};

function createBaseMsgOverwriteUnbondingRecord(): MsgOverwriteUnbondingRecord {
  return { creator: "", unbondingRecord: undefined };
}

export const MsgOverwriteUnbondingRecord = {
  encode(
    message: MsgOverwriteUnbondingRecord,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.unbondingRecord !== undefined) {
      UnbondingRecord.encode(
        message.unbondingRecord,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgOverwriteUnbondingRecord {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgOverwriteUnbondingRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.unbondingRecord = UnbondingRecord.decode(
            reader,
            reader.uint32()
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgOverwriteUnbondingRecord {
    return {
      creator: isSet(object.creator) ? globalThis.String(object.creator) : "",
      unbondingRecord: isSet(object.unbondingRecord)
        ? UnbondingRecord.fromJSON(object.unbondingRecord)
        : undefined,
    };
  },

  toJSON(message: MsgOverwriteUnbondingRecord): unknown {
    const obj: any = {};
    if (message.creator !== "") {
      obj.creator = message.creator;
    }
    if (message.unbondingRecord !== undefined) {
      obj.unbondingRecord = UnbondingRecord.toJSON(message.unbondingRecord);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgOverwriteUnbondingRecord>, I>>(
    base?: I
  ): MsgOverwriteUnbondingRecord {
    return MsgOverwriteUnbondingRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgOverwriteUnbondingRecord>, I>>(
    object: I
  ): MsgOverwriteUnbondingRecord {
    const message = createBaseMsgOverwriteUnbondingRecord();
    message.creator = object.creator ?? "";
    message.unbondingRecord =
      object.unbondingRecord !== undefined && object.unbondingRecord !== null
        ? UnbondingRecord.fromPartial(object.unbondingRecord)
        : undefined;
    return message;
  },
};

function createBaseMsgOverwriteUnbondingRecordResponse(): MsgOverwriteUnbondingRecordResponse {
  return {};
}

export const MsgOverwriteUnbondingRecordResponse = {
  encode(
    _: MsgOverwriteUnbondingRecordResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgOverwriteUnbondingRecordResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgOverwriteUnbondingRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgOverwriteUnbondingRecordResponse {
    return {};
  },

  toJSON(_: MsgOverwriteUnbondingRecordResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgOverwriteUnbondingRecordResponse>, I>>(
    base?: I
  ): MsgOverwriteUnbondingRecordResponse {
    return MsgOverwriteUnbondingRecordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<MsgOverwriteUnbondingRecordResponse>, I>
  >(_: I): MsgOverwriteUnbondingRecordResponse {
    const message = createBaseMsgOverwriteUnbondingRecordResponse();
    return message;
  },
};

function createBaseMsgOverwriteRedemptionRecord(): MsgOverwriteRedemptionRecord {
  return { creator: "", redemptionRecord: undefined };
}

export const MsgOverwriteRedemptionRecord = {
  encode(
    message: MsgOverwriteRedemptionRecord,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.redemptionRecord !== undefined) {
      RedemptionRecord.encode(
        message.redemptionRecord,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgOverwriteRedemptionRecord {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgOverwriteRedemptionRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.redemptionRecord = RedemptionRecord.decode(
            reader,
            reader.uint32()
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgOverwriteRedemptionRecord {
    return {
      creator: isSet(object.creator) ? globalThis.String(object.creator) : "",
      redemptionRecord: isSet(object.redemptionRecord)
        ? RedemptionRecord.fromJSON(object.redemptionRecord)
        : undefined,
    };
  },

  toJSON(message: MsgOverwriteRedemptionRecord): unknown {
    const obj: any = {};
    if (message.creator !== "") {
      obj.creator = message.creator;
    }
    if (message.redemptionRecord !== undefined) {
      obj.redemptionRecord = RedemptionRecord.toJSON(message.redemptionRecord);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgOverwriteRedemptionRecord>, I>>(
    base?: I
  ): MsgOverwriteRedemptionRecord {
    return MsgOverwriteRedemptionRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgOverwriteRedemptionRecord>, I>>(
    object: I
  ): MsgOverwriteRedemptionRecord {
    const message = createBaseMsgOverwriteRedemptionRecord();
    message.creator = object.creator ?? "";
    message.redemptionRecord =
      object.redemptionRecord !== undefined && object.redemptionRecord !== null
        ? RedemptionRecord.fromPartial(object.redemptionRecord)
        : undefined;
    return message;
  },
};

function createBaseMsgOverwriteRedemptionRecordResponse(): MsgOverwriteRedemptionRecordResponse {
  return {};
}

export const MsgOverwriteRedemptionRecordResponse = {
  encode(
    _: MsgOverwriteRedemptionRecordResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgOverwriteRedemptionRecordResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgOverwriteRedemptionRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgOverwriteRedemptionRecordResponse {
    return {};
  },

  toJSON(_: MsgOverwriteRedemptionRecordResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgOverwriteRedemptionRecordResponse>, I>>(
    base?: I
  ): MsgOverwriteRedemptionRecordResponse {
    return MsgOverwriteRedemptionRecordResponse.fromPartial(
      base ?? ({} as any)
    );
  },
  fromPartial<
    I extends Exact<DeepPartial<MsgOverwriteRedemptionRecordResponse>, I>
  >(_: I): MsgOverwriteRedemptionRecordResponse {
    const message = createBaseMsgOverwriteRedemptionRecordResponse();
    return message;
  },
};

function createBaseMsgSetOperatorAddress(): MsgSetOperatorAddress {
  return { signer: "", operator: "" };
}

export const MsgSetOperatorAddress = {
  encode(
    message: MsgSetOperatorAddress,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.signer !== "") {
      writer.uint32(10).string(message.signer);
    }
    if (message.operator !== "") {
      writer.uint32(18).string(message.operator);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgSetOperatorAddress {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgSetOperatorAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.signer = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.operator = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgSetOperatorAddress {
    return {
      signer: isSet(object.signer) ? globalThis.String(object.signer) : "",
      operator: isSet(object.operator)
        ? globalThis.String(object.operator)
        : "",
    };
  },

  toJSON(message: MsgSetOperatorAddress): unknown {
    const obj: any = {};
    if (message.signer !== "") {
      obj.signer = message.signer;
    }
    if (message.operator !== "") {
      obj.operator = message.operator;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgSetOperatorAddress>, I>>(
    base?: I
  ): MsgSetOperatorAddress {
    return MsgSetOperatorAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgSetOperatorAddress>, I>>(
    object: I
  ): MsgSetOperatorAddress {
    const message = createBaseMsgSetOperatorAddress();
    message.signer = object.signer ?? "";
    message.operator = object.operator ?? "";
    return message;
  },
};

function createBaseMsgSetOperatorAddressResponse(): MsgSetOperatorAddressResponse {
  return {};
}

export const MsgSetOperatorAddressResponse = {
  encode(
    _: MsgSetOperatorAddressResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): MsgSetOperatorAddressResponse {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgSetOperatorAddressResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgSetOperatorAddressResponse {
    return {};
  },

  toJSON(_: MsgSetOperatorAddressResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgSetOperatorAddressResponse>, I>>(
    base?: I
  ): MsgSetOperatorAddressResponse {
    return MsgSetOperatorAddressResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgSetOperatorAddressResponse>, I>>(
    _: I
  ): MsgSetOperatorAddressResponse {
    const message = createBaseMsgSetOperatorAddressResponse();
    return message;
  },
};

/** Msg defines the Msg service. */
export interface Msg {
  /** User transaction to liquid stake native tokens into stTokens */
  LiquidStake(request: MsgLiquidStake): Promise<MsgLiquidStakeResponse>;
  /** User transaction to redeem stake stTokens into native tokens */
  RedeemStake(request: MsgRedeemStake): Promise<MsgRedeemStakeResponse>;
  /**
   * Operator transaction to confirm a delegation was submitted
   * on the host chain
   */
  ConfirmDelegation(
    request: MsgConfirmDelegation
  ): Promise<MsgConfirmDelegationResponse>;
  /**
   * Operator transaction to confirm an undelegation was submitted
   * on the host chain
   */
  ConfirmUndelegation(
    request: MsgConfirmUndelegation
  ): Promise<MsgConfirmUndelegationResponse>;
  /**
   * Operator transaction to confirm unbonded tokens were transferred back to
   * stride
   */
  ConfirmUnbondedTokenSweep(
    request: MsgConfirmUnbondedTokenSweep
  ): Promise<MsgConfirmUnbondedTokenSweepResponse>;
  /**
   * Operator transaction to adjust the delegated balance after a validator was
   * slashed
   */
  AdjustDelegatedBalance(
    request: MsgAdjustDelegatedBalance
  ): Promise<MsgAdjustDelegatedBalanceResponse>;
  /** Adjusts the inner redemption rate bounds on the host zone */
  UpdateInnerRedemptionRateBounds(
    request: MsgUpdateInnerRedemptionRateBounds
  ): Promise<MsgUpdateInnerRedemptionRateBoundsResponse>;
  /** Unhalts the host zone if redemption rates were exceeded */
  ResumeHostZone(
    request: MsgResumeHostZone
  ): Promise<MsgResumeHostZoneResponse>;
  /** Trigger updating the redemption rate */
  RefreshRedemptionRate(
    request: MsgRefreshRedemptionRate
  ): Promise<MsgRefreshRedemptionRateResponse>;
  /** Overwrites a delegation record */
  OverwriteDelegationRecord(
    request: MsgOverwriteDelegationRecord
  ): Promise<MsgOverwriteDelegationRecordResponse>;
  /** Overwrites a unbonding record */
  OverwriteUnbondingRecord(
    request: MsgOverwriteUnbondingRecord
  ): Promise<MsgOverwriteUnbondingRecordResponse>;
  /** Overwrites a redemption record */
  OverwriteRedemptionRecord(
    request: MsgOverwriteRedemptionRecord
  ): Promise<MsgOverwriteRedemptionRecordResponse>;
  /** Sets the operator address */
  SetOperatorAddress(
    request: MsgSetOperatorAddress
  ): Promise<MsgSetOperatorAddressResponse>;
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
