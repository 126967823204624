// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.21.3
// source: stride/staketia/staketia.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "stride.staketia";

/**
 * Status fields for a delegation record
 * Note: There is an important assumption here that tokens in the deposit
 * account should not be tracked by these records. The record is created as soon
 * as the tokens leave stride
 * Additionally, the GetActiveDelegationRecords query filters for records that
 * are either TRANSFER_IN_PROGERSS or DELEGATION_QUEUE. If a new active status
 * is added, the keeper must be modified
 */
export enum DelegationRecordStatus {
  /**
   * TRANSFER_IN_PROGRESS - TRANSFER_IN_PROGRESS indicates the native tokens are being sent from the
   * deposit account to the delegation account
   */
  TRANSFER_IN_PROGRESS = 0,
  /**
   * TRANSFER_FAILED - TRANSFER_FAILED indicates that the transfer either timed out or was an ack
   * failure
   */
  TRANSFER_FAILED = 1,
  /**
   * DELEGATION_QUEUE - DELEGATION_QUEUE indicates the tokens have landed on the host zone and are
   * ready to be delegated
   */
  DELEGATION_QUEUE = 2,
  /** DELEGATION_COMPLETE - DELEGATION_COMPLETE indicates the delegation has been completed */
  DELEGATION_COMPLETE = 3,
  UNRECOGNIZED = -1,
}

export function delegationRecordStatusFromJSON(
  object: any
): DelegationRecordStatus {
  switch (object) {
    case 0:
    case "TRANSFER_IN_PROGRESS":
      return DelegationRecordStatus.TRANSFER_IN_PROGRESS;
    case 1:
    case "TRANSFER_FAILED":
      return DelegationRecordStatus.TRANSFER_FAILED;
    case 2:
    case "DELEGATION_QUEUE":
      return DelegationRecordStatus.DELEGATION_QUEUE;
    case 3:
    case "DELEGATION_COMPLETE":
      return DelegationRecordStatus.DELEGATION_COMPLETE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DelegationRecordStatus.UNRECOGNIZED;
  }
}

export function delegationRecordStatusToJSON(
  object: DelegationRecordStatus
): string {
  switch (object) {
    case DelegationRecordStatus.TRANSFER_IN_PROGRESS:
      return "TRANSFER_IN_PROGRESS";
    case DelegationRecordStatus.TRANSFER_FAILED:
      return "TRANSFER_FAILED";
    case DelegationRecordStatus.DELEGATION_QUEUE:
      return "DELEGATION_QUEUE";
    case DelegationRecordStatus.DELEGATION_COMPLETE:
      return "DELEGATION_COMPLETE";
    case DelegationRecordStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Status fields for an unbonding record */
export enum UnbondingRecordStatus {
  /**
   * ACCUMULATING_REDEMPTIONS - ACCUMULATING_REDEMPTIONS indicates redemptions are still being accumulated
   * on this record
   */
  ACCUMULATING_REDEMPTIONS = 0,
  /**
   * UNBONDING_QUEUE - UNBONDING_QUEUE indicates the unbond amount for this epoch has been froze
   * and the tokens are ready to be unbonded on the host zone
   */
  UNBONDING_QUEUE = 1,
  /**
   * UNBONDING_IN_PROGRESS - UNBONDING_IN_PROGRESS indicates the unbonding is currently in progress on
   * the host zone
   */
  UNBONDING_IN_PROGRESS = 2,
  /**
   * UNBONDED - UNBONDED indicates the unbonding is finished on the host zone and the
   * tokens are still in the delegation account
   */
  UNBONDED = 3,
  /**
   * CLAIMABLE - CLAIMABLE indicates the unbonded tokens have been swept to stride and are
   * ready to be distributed to users
   */
  CLAIMABLE = 4,
  /** CLAIMED - CLAIMED indicates the full unbonding cycle has been completed */
  CLAIMED = 5,
  UNRECOGNIZED = -1,
}

export function unbondingRecordStatusFromJSON(
  object: any
): UnbondingRecordStatus {
  switch (object) {
    case 0:
    case "ACCUMULATING_REDEMPTIONS":
      return UnbondingRecordStatus.ACCUMULATING_REDEMPTIONS;
    case 1:
    case "UNBONDING_QUEUE":
      return UnbondingRecordStatus.UNBONDING_QUEUE;
    case 2:
    case "UNBONDING_IN_PROGRESS":
      return UnbondingRecordStatus.UNBONDING_IN_PROGRESS;
    case 3:
    case "UNBONDED":
      return UnbondingRecordStatus.UNBONDED;
    case 4:
    case "CLAIMABLE":
      return UnbondingRecordStatus.CLAIMABLE;
    case 5:
    case "CLAIMED":
      return UnbondingRecordStatus.CLAIMED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UnbondingRecordStatus.UNRECOGNIZED;
  }
}

export function unbondingRecordStatusToJSON(
  object: UnbondingRecordStatus
): string {
  switch (object) {
    case UnbondingRecordStatus.ACCUMULATING_REDEMPTIONS:
      return "ACCUMULATING_REDEMPTIONS";
    case UnbondingRecordStatus.UNBONDING_QUEUE:
      return "UNBONDING_QUEUE";
    case UnbondingRecordStatus.UNBONDING_IN_PROGRESS:
      return "UNBONDING_IN_PROGRESS";
    case UnbondingRecordStatus.UNBONDED:
      return "UNBONDED";
    case UnbondingRecordStatus.CLAIMABLE:
      return "CLAIMABLE";
    case UnbondingRecordStatus.CLAIMED:
      return "CLAIMED";
    case UnbondingRecordStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface HostZone {
  /** Chain ID */
  chainId: string;
  /** Native token denom on the host zone (e.g. utia) */
  nativeTokenDenom: string;
  /** IBC denom of the native token as it lives on stride (e.g. ibc/...) */
  nativeTokenIbcDenom: string;
  /** Transfer channel ID from stride to the host zone */
  transferChannelId: string;
  /** Operator controlled delegation address on the host zone */
  delegationAddress: string;
  /** Operator controlled reward address on the host zone */
  rewardAddress: string;
  /** Deposit address on stride */
  depositAddress: string;
  /** Redemption address on stride */
  redemptionAddress: string;
  /** Claim address on stride */
  claimAddress: string;
  /** operator address set by safe, on stride */
  operatorAddressOnStride: string;
  /** admin address set upon host zone creation,  on stride */
  safeAddressOnStride: string;
  /** Previous redemption rate */
  lastRedemptionRate: string;
  /** Current redemption rate */
  redemptionRate: string;
  /** Min outer redemption rate - adjusted by governance */
  minRedemptionRate: string;
  /** Max outer redemption rate - adjusted by governance */
  maxRedemptionRate: string;
  /** Min inner redemption rate - adjusted by controller */
  minInnerRedemptionRate: string;
  /** Max inner redemption rate - adjusted by controller */
  maxInnerRedemptionRate: string;
  /** Total delegated balance on the host zone delegation account */
  delegatedBalance: string;
  /** The undelegation period for Celestia in days */
  unbondingPeriodSeconds: string;
  /** Indicates whether the host zone has been halted */
  halted: boolean;
}

/**
 * DelegationRecords track the aggregate liquid stakes and delegations
 * for a given epoch
 * Note: There is an important assumption here that tokens in the deposit
 * account should not be tracked by these records. The record is created as soon
 * as the tokens leave stride
 */
export interface DelegationRecord {
  /** Deposit record unique ID */
  id: string;
  /** The amount of native tokens that should be delegated */
  nativeAmount: string;
  /** The status indicating the point in the delegation's lifecycle */
  status: DelegationRecordStatus;
  /** The tx hash of the delegation on the host zone */
  txHash: string;
}

/** UnbondingRecords track the aggregate unbondings across an epoch */
export interface UnbondingRecord {
  /** Unbonding record ID */
  id: string;
  /** The status indicating the point in the delegation's lifecycle */
  status: UnbondingRecordStatus;
  /** The amount of stTokens that were redeemed */
  stTokenAmount: string;
  /** The corresponding amount of native tokens that should be unbonded */
  nativeAmount: string;
  /** The Unix timestamp (in seconds) at which the unbonding completes */
  unbondingCompletionTimeSeconds: string;
  /** The tx hash of the undelegation on the host zone */
  undelegationTxHash: string;
  /** The tx hash of the unbonded token sweep on the host zone */
  unbondedTokenSweepTxHash: string;
}

/** RedemptionRecords track an individual user's redemption claims */
export interface RedemptionRecord {
  /** Unbonding record ID */
  unbondingRecordId: string;
  /** Redeemer */
  redeemer: string;
  /** The amount of stTokens that were redeemed */
  stTokenAmount: string;
  /** The corresponding amount of native tokens that should be unbonded */
  nativeAmount: string;
}

/** SlashRecords log adjustments to the delegated balance */
export interface SlashRecord {
  /** The slash record monotonically increasing ID */
  id: string;
  /**
   * The Unix timestamp (in seconds) when the slash adjustment was processed on
   * stride
   */
  time: string;
  /** The delta by which the total delegated amount changed from slash */
  nativeAmount: string;
  /** The address (or addresses) of the validator that was slashed */
  validatorAddress: string;
}

function createBaseHostZone(): HostZone {
  return {
    chainId: "",
    nativeTokenDenom: "",
    nativeTokenIbcDenom: "",
    transferChannelId: "",
    delegationAddress: "",
    rewardAddress: "",
    depositAddress: "",
    redemptionAddress: "",
    claimAddress: "",
    operatorAddressOnStride: "",
    safeAddressOnStride: "",
    lastRedemptionRate: "",
    redemptionRate: "",
    minRedemptionRate: "",
    maxRedemptionRate: "",
    minInnerRedemptionRate: "",
    maxInnerRedemptionRate: "",
    delegatedBalance: "",
    unbondingPeriodSeconds: "0",
    halted: false,
  };
}

export const HostZone = {
  encode(
    message: HostZone,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.chainId !== "") {
      writer.uint32(10).string(message.chainId);
    }
    if (message.nativeTokenDenom !== "") {
      writer.uint32(18).string(message.nativeTokenDenom);
    }
    if (message.nativeTokenIbcDenom !== "") {
      writer.uint32(26).string(message.nativeTokenIbcDenom);
    }
    if (message.transferChannelId !== "") {
      writer.uint32(34).string(message.transferChannelId);
    }
    if (message.delegationAddress !== "") {
      writer.uint32(42).string(message.delegationAddress);
    }
    if (message.rewardAddress !== "") {
      writer.uint32(50).string(message.rewardAddress);
    }
    if (message.depositAddress !== "") {
      writer.uint32(58).string(message.depositAddress);
    }
    if (message.redemptionAddress !== "") {
      writer.uint32(66).string(message.redemptionAddress);
    }
    if (message.claimAddress !== "") {
      writer.uint32(74).string(message.claimAddress);
    }
    if (message.operatorAddressOnStride !== "") {
      writer.uint32(82).string(message.operatorAddressOnStride);
    }
    if (message.safeAddressOnStride !== "") {
      writer.uint32(90).string(message.safeAddressOnStride);
    }
    if (message.lastRedemptionRate !== "") {
      writer.uint32(98).string(message.lastRedemptionRate);
    }
    if (message.redemptionRate !== "") {
      writer.uint32(106).string(message.redemptionRate);
    }
    if (message.minRedemptionRate !== "") {
      writer.uint32(114).string(message.minRedemptionRate);
    }
    if (message.maxRedemptionRate !== "") {
      writer.uint32(122).string(message.maxRedemptionRate);
    }
    if (message.minInnerRedemptionRate !== "") {
      writer.uint32(130).string(message.minInnerRedemptionRate);
    }
    if (message.maxInnerRedemptionRate !== "") {
      writer.uint32(138).string(message.maxInnerRedemptionRate);
    }
    if (message.delegatedBalance !== "") {
      writer.uint32(146).string(message.delegatedBalance);
    }
    if (message.unbondingPeriodSeconds !== "0") {
      writer.uint32(152).uint64(message.unbondingPeriodSeconds);
    }
    if (message.halted !== false) {
      writer.uint32(160).bool(message.halted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HostZone {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHostZone();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nativeTokenDenom = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nativeTokenIbcDenom = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.transferChannelId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.delegationAddress = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.rewardAddress = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.depositAddress = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.redemptionAddress = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.claimAddress = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.operatorAddressOnStride = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.safeAddressOnStride = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lastRedemptionRate = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.redemptionRate = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.minRedemptionRate = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.maxRedemptionRate = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.minInnerRedemptionRate = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.maxInnerRedemptionRate = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.delegatedBalance = reader.string();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.unbondingPeriodSeconds = longToString(
            reader.uint64() as Long
          );
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.halted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HostZone {
    return {
      chainId: isSet(object.chainId) ? globalThis.String(object.chainId) : "",
      nativeTokenDenom: isSet(object.nativeTokenDenom)
        ? globalThis.String(object.nativeTokenDenom)
        : "",
      nativeTokenIbcDenom: isSet(object.nativeTokenIbcDenom)
        ? globalThis.String(object.nativeTokenIbcDenom)
        : "",
      transferChannelId: isSet(object.transferChannelId)
        ? globalThis.String(object.transferChannelId)
        : "",
      delegationAddress: isSet(object.delegationAddress)
        ? globalThis.String(object.delegationAddress)
        : "",
      rewardAddress: isSet(object.rewardAddress)
        ? globalThis.String(object.rewardAddress)
        : "",
      depositAddress: isSet(object.depositAddress)
        ? globalThis.String(object.depositAddress)
        : "",
      redemptionAddress: isSet(object.redemptionAddress)
        ? globalThis.String(object.redemptionAddress)
        : "",
      claimAddress: isSet(object.claimAddress)
        ? globalThis.String(object.claimAddress)
        : "",
      operatorAddressOnStride: isSet(object.operatorAddressOnStride)
        ? globalThis.String(object.operatorAddressOnStride)
        : "",
      safeAddressOnStride: isSet(object.safeAddressOnStride)
        ? globalThis.String(object.safeAddressOnStride)
        : "",
      lastRedemptionRate: isSet(object.lastRedemptionRate)
        ? globalThis.String(object.lastRedemptionRate)
        : "",
      redemptionRate: isSet(object.redemptionRate)
        ? globalThis.String(object.redemptionRate)
        : "",
      minRedemptionRate: isSet(object.minRedemptionRate)
        ? globalThis.String(object.minRedemptionRate)
        : "",
      maxRedemptionRate: isSet(object.maxRedemptionRate)
        ? globalThis.String(object.maxRedemptionRate)
        : "",
      minInnerRedemptionRate: isSet(object.minInnerRedemptionRate)
        ? globalThis.String(object.minInnerRedemptionRate)
        : "",
      maxInnerRedemptionRate: isSet(object.maxInnerRedemptionRate)
        ? globalThis.String(object.maxInnerRedemptionRate)
        : "",
      delegatedBalance: isSet(object.delegatedBalance)
        ? globalThis.String(object.delegatedBalance)
        : "",
      unbondingPeriodSeconds: isSet(object.unbondingPeriodSeconds)
        ? globalThis.String(object.unbondingPeriodSeconds)
        : "0",
      halted: isSet(object.halted) ? globalThis.Boolean(object.halted) : false,
    };
  },

  toJSON(message: HostZone): unknown {
    const obj: any = {};
    if (message.chainId !== "") {
      obj.chainId = message.chainId;
    }
    if (message.nativeTokenDenom !== "") {
      obj.nativeTokenDenom = message.nativeTokenDenom;
    }
    if (message.nativeTokenIbcDenom !== "") {
      obj.nativeTokenIbcDenom = message.nativeTokenIbcDenom;
    }
    if (message.transferChannelId !== "") {
      obj.transferChannelId = message.transferChannelId;
    }
    if (message.delegationAddress !== "") {
      obj.delegationAddress = message.delegationAddress;
    }
    if (message.rewardAddress !== "") {
      obj.rewardAddress = message.rewardAddress;
    }
    if (message.depositAddress !== "") {
      obj.depositAddress = message.depositAddress;
    }
    if (message.redemptionAddress !== "") {
      obj.redemptionAddress = message.redemptionAddress;
    }
    if (message.claimAddress !== "") {
      obj.claimAddress = message.claimAddress;
    }
    if (message.operatorAddressOnStride !== "") {
      obj.operatorAddressOnStride = message.operatorAddressOnStride;
    }
    if (message.safeAddressOnStride !== "") {
      obj.safeAddressOnStride = message.safeAddressOnStride;
    }
    if (message.lastRedemptionRate !== "") {
      obj.lastRedemptionRate = message.lastRedemptionRate;
    }
    if (message.redemptionRate !== "") {
      obj.redemptionRate = message.redemptionRate;
    }
    if (message.minRedemptionRate !== "") {
      obj.minRedemptionRate = message.minRedemptionRate;
    }
    if (message.maxRedemptionRate !== "") {
      obj.maxRedemptionRate = message.maxRedemptionRate;
    }
    if (message.minInnerRedemptionRate !== "") {
      obj.minInnerRedemptionRate = message.minInnerRedemptionRate;
    }
    if (message.maxInnerRedemptionRate !== "") {
      obj.maxInnerRedemptionRate = message.maxInnerRedemptionRate;
    }
    if (message.delegatedBalance !== "") {
      obj.delegatedBalance = message.delegatedBalance;
    }
    if (message.unbondingPeriodSeconds !== "0") {
      obj.unbondingPeriodSeconds = message.unbondingPeriodSeconds;
    }
    if (message.halted !== false) {
      obj.halted = message.halted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HostZone>, I>>(base?: I): HostZone {
    return HostZone.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HostZone>, I>>(object: I): HostZone {
    const message = createBaseHostZone();
    message.chainId = object.chainId ?? "";
    message.nativeTokenDenom = object.nativeTokenDenom ?? "";
    message.nativeTokenIbcDenom = object.nativeTokenIbcDenom ?? "";
    message.transferChannelId = object.transferChannelId ?? "";
    message.delegationAddress = object.delegationAddress ?? "";
    message.rewardAddress = object.rewardAddress ?? "";
    message.depositAddress = object.depositAddress ?? "";
    message.redemptionAddress = object.redemptionAddress ?? "";
    message.claimAddress = object.claimAddress ?? "";
    message.operatorAddressOnStride = object.operatorAddressOnStride ?? "";
    message.safeAddressOnStride = object.safeAddressOnStride ?? "";
    message.lastRedemptionRate = object.lastRedemptionRate ?? "";
    message.redemptionRate = object.redemptionRate ?? "";
    message.minRedemptionRate = object.minRedemptionRate ?? "";
    message.maxRedemptionRate = object.maxRedemptionRate ?? "";
    message.minInnerRedemptionRate = object.minInnerRedemptionRate ?? "";
    message.maxInnerRedemptionRate = object.maxInnerRedemptionRate ?? "";
    message.delegatedBalance = object.delegatedBalance ?? "";
    message.unbondingPeriodSeconds = object.unbondingPeriodSeconds ?? "0";
    message.halted = object.halted ?? false;
    return message;
  },
};

function createBaseDelegationRecord(): DelegationRecord {
  return { id: "0", nativeAmount: "", status: 0, txHash: "" };
}

export const DelegationRecord = {
  encode(
    message: DelegationRecord,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.nativeAmount !== "") {
      writer.uint32(18).string(message.nativeAmount);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.txHash !== "") {
      writer.uint32(34).string(message.txHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DelegationRecord {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDelegationRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nativeAmount = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.txHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DelegationRecord {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      nativeAmount: isSet(object.nativeAmount)
        ? globalThis.String(object.nativeAmount)
        : "",
      status: isSet(object.status)
        ? delegationRecordStatusFromJSON(object.status)
        : 0,
      txHash: isSet(object.txHash) ? globalThis.String(object.txHash) : "",
    };
  },

  toJSON(message: DelegationRecord): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.nativeAmount !== "") {
      obj.nativeAmount = message.nativeAmount;
    }
    if (message.status !== 0) {
      obj.status = delegationRecordStatusToJSON(message.status);
    }
    if (message.txHash !== "") {
      obj.txHash = message.txHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DelegationRecord>, I>>(
    base?: I
  ): DelegationRecord {
    return DelegationRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DelegationRecord>, I>>(
    object: I
  ): DelegationRecord {
    const message = createBaseDelegationRecord();
    message.id = object.id ?? "0";
    message.nativeAmount = object.nativeAmount ?? "";
    message.status = object.status ?? 0;
    message.txHash = object.txHash ?? "";
    return message;
  },
};

function createBaseUnbondingRecord(): UnbondingRecord {
  return {
    id: "0",
    status: 0,
    stTokenAmount: "",
    nativeAmount: "",
    unbondingCompletionTimeSeconds: "0",
    undelegationTxHash: "",
    unbondedTokenSweepTxHash: "",
  };
}

export const UnbondingRecord = {
  encode(
    message: UnbondingRecord,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.stTokenAmount !== "") {
      writer.uint32(26).string(message.stTokenAmount);
    }
    if (message.nativeAmount !== "") {
      writer.uint32(34).string(message.nativeAmount);
    }
    if (message.unbondingCompletionTimeSeconds !== "0") {
      writer.uint32(40).uint64(message.unbondingCompletionTimeSeconds);
    }
    if (message.undelegationTxHash !== "") {
      writer.uint32(50).string(message.undelegationTxHash);
    }
    if (message.unbondedTokenSweepTxHash !== "") {
      writer.uint32(58).string(message.unbondedTokenSweepTxHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnbondingRecord {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnbondingRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.stTokenAmount = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nativeAmount = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.unbondingCompletionTimeSeconds = longToString(
            reader.uint64() as Long
          );
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.undelegationTxHash = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.unbondedTokenSweepTxHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnbondingRecord {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      status: isSet(object.status)
        ? unbondingRecordStatusFromJSON(object.status)
        : 0,
      stTokenAmount: isSet(object.stTokenAmount)
        ? globalThis.String(object.stTokenAmount)
        : "",
      nativeAmount: isSet(object.nativeAmount)
        ? globalThis.String(object.nativeAmount)
        : "",
      unbondingCompletionTimeSeconds: isSet(
        object.unbondingCompletionTimeSeconds
      )
        ? globalThis.String(object.unbondingCompletionTimeSeconds)
        : "0",
      undelegationTxHash: isSet(object.undelegationTxHash)
        ? globalThis.String(object.undelegationTxHash)
        : "",
      unbondedTokenSweepTxHash: isSet(object.unbondedTokenSweepTxHash)
        ? globalThis.String(object.unbondedTokenSweepTxHash)
        : "",
    };
  },

  toJSON(message: UnbondingRecord): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.status !== 0) {
      obj.status = unbondingRecordStatusToJSON(message.status);
    }
    if (message.stTokenAmount !== "") {
      obj.stTokenAmount = message.stTokenAmount;
    }
    if (message.nativeAmount !== "") {
      obj.nativeAmount = message.nativeAmount;
    }
    if (message.unbondingCompletionTimeSeconds !== "0") {
      obj.unbondingCompletionTimeSeconds =
        message.unbondingCompletionTimeSeconds;
    }
    if (message.undelegationTxHash !== "") {
      obj.undelegationTxHash = message.undelegationTxHash;
    }
    if (message.unbondedTokenSweepTxHash !== "") {
      obj.unbondedTokenSweepTxHash = message.unbondedTokenSweepTxHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnbondingRecord>, I>>(
    base?: I
  ): UnbondingRecord {
    return UnbondingRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnbondingRecord>, I>>(
    object: I
  ): UnbondingRecord {
    const message = createBaseUnbondingRecord();
    message.id = object.id ?? "0";
    message.status = object.status ?? 0;
    message.stTokenAmount = object.stTokenAmount ?? "";
    message.nativeAmount = object.nativeAmount ?? "";
    message.unbondingCompletionTimeSeconds =
      object.unbondingCompletionTimeSeconds ?? "0";
    message.undelegationTxHash = object.undelegationTxHash ?? "";
    message.unbondedTokenSweepTxHash = object.unbondedTokenSweepTxHash ?? "";
    return message;
  },
};

function createBaseRedemptionRecord(): RedemptionRecord {
  return {
    unbondingRecordId: "0",
    redeemer: "",
    stTokenAmount: "",
    nativeAmount: "",
  };
}

export const RedemptionRecord = {
  encode(
    message: RedemptionRecord,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.unbondingRecordId !== "0") {
      writer.uint32(8).uint64(message.unbondingRecordId);
    }
    if (message.redeemer !== "") {
      writer.uint32(18).string(message.redeemer);
    }
    if (message.stTokenAmount !== "") {
      writer.uint32(26).string(message.stTokenAmount);
    }
    if (message.nativeAmount !== "") {
      writer.uint32(34).string(message.nativeAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RedemptionRecord {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRedemptionRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.unbondingRecordId = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.redeemer = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.stTokenAmount = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nativeAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RedemptionRecord {
    return {
      unbondingRecordId: isSet(object.unbondingRecordId)
        ? globalThis.String(object.unbondingRecordId)
        : "0",
      redeemer: isSet(object.redeemer)
        ? globalThis.String(object.redeemer)
        : "",
      stTokenAmount: isSet(object.stTokenAmount)
        ? globalThis.String(object.stTokenAmount)
        : "",
      nativeAmount: isSet(object.nativeAmount)
        ? globalThis.String(object.nativeAmount)
        : "",
    };
  },

  toJSON(message: RedemptionRecord): unknown {
    const obj: any = {};
    if (message.unbondingRecordId !== "0") {
      obj.unbondingRecordId = message.unbondingRecordId;
    }
    if (message.redeemer !== "") {
      obj.redeemer = message.redeemer;
    }
    if (message.stTokenAmount !== "") {
      obj.stTokenAmount = message.stTokenAmount;
    }
    if (message.nativeAmount !== "") {
      obj.nativeAmount = message.nativeAmount;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RedemptionRecord>, I>>(
    base?: I
  ): RedemptionRecord {
    return RedemptionRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RedemptionRecord>, I>>(
    object: I
  ): RedemptionRecord {
    const message = createBaseRedemptionRecord();
    message.unbondingRecordId = object.unbondingRecordId ?? "0";
    message.redeemer = object.redeemer ?? "";
    message.stTokenAmount = object.stTokenAmount ?? "";
    message.nativeAmount = object.nativeAmount ?? "";
    return message;
  },
};

function createBaseSlashRecord(): SlashRecord {
  return { id: "0", time: "0", nativeAmount: "", validatorAddress: "" };
}

export const SlashRecord = {
  encode(
    message: SlashRecord,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "0") {
      writer.uint32(8).uint64(message.id);
    }
    if (message.time !== "0") {
      writer.uint32(16).uint64(message.time);
    }
    if (message.nativeAmount !== "") {
      writer.uint32(26).string(message.nativeAmount);
    }
    if (message.validatorAddress !== "") {
      writer.uint32(34).string(message.validatorAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlashRecord {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlashRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToString(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.time = longToString(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nativeAmount = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.validatorAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SlashRecord {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "0",
      time: isSet(object.time) ? globalThis.String(object.time) : "0",
      nativeAmount: isSet(object.nativeAmount)
        ? globalThis.String(object.nativeAmount)
        : "",
      validatorAddress: isSet(object.validatorAddress)
        ? globalThis.String(object.validatorAddress)
        : "",
    };
  },

  toJSON(message: SlashRecord): unknown {
    const obj: any = {};
    if (message.id !== "0") {
      obj.id = message.id;
    }
    if (message.time !== "0") {
      obj.time = message.time;
    }
    if (message.nativeAmount !== "") {
      obj.nativeAmount = message.nativeAmount;
    }
    if (message.validatorAddress !== "") {
      obj.validatorAddress = message.validatorAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SlashRecord>, I>>(base?: I): SlashRecord {
    return SlashRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SlashRecord>, I>>(
    object: I
  ): SlashRecord {
    const message = createBaseSlashRecord();
    message.id = object.id ?? "0";
    message.time = object.time ?? "0";
    message.nativeAmount = object.nativeAmount ?? "";
    message.validatorAddress = object.validatorAddress ?? "";
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
