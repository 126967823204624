import { Bech32Address } from "@owallet/cosmos";

export const EmbedChainInfos = [
  {
    rpc: "https://rpc-cosmoshub.keplr.app",
    rest: "https://lcd-cosmoshub.keplr.app",
    chainId: "cosmoshub-4",
    chainName: "Cosmos",
    stakeCurrency: {
      coinDenom: "ATOM",
      coinMinimalDenom: "uatom",
      coinDecimals: 6,
      coinGeckoId: "cosmos",
    },
    walletUrl:
      process.env["NODE_ENV"] === "production"
        ? "https://wallet.keplr.app/chains/cosmos-hub"
        : "http://localhost:8081/chains/cosmos-hub",
    walletUrlForStaking:
      process.env["NODE_ENV"] === "production"
        ? "https://wallet.keplr.app/chains/cosmos-hub"
        : "http://localhost:8081/chains/cosmos-hub",
    bip44: {
      coinType: 118,
    },
    bech32Config: Bech32Address.defaultBech32Config("cosmos"),
    currencies: [
      {
        coinDenom: "ATOM",
        coinMinimalDenom: "uatom",
        coinDecimals: 6,
        coinGeckoId: "cosmos",
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "ATOM",
        coinMinimalDenom: "uatom",
        coinDecimals: 6,
        coinGeckoId: "cosmos",
      },
    ],
    coinType: 118,
    features: ["stargate", "ibc-transfer"],
  },
  {
    rpc: "https://rpc-osmosis.keplr.app",
    rest: "https://lcd-osmosis.keplr.app",
    chainId: "osmosis-1",
    chainName: "Osmosis",
    stakeCurrency: {
      coinDenom: "OSMO",
      coinMinimalDenom: "uosmo",
      coinDecimals: 6,
      coinGeckoId: "osmosis",
    },
    walletUrl:
      process.env["NODE_ENV"] === "production"
        ? "https://app.osmosis.zone"
        : "https://app.osmosis.zone",
    walletUrlForStaking:
      process.env["NODE_ENV"] === "production"
        ? "https://wallet.keplr.app/chains/osmosis"
        : "http://localhost:8081/chains/osmosis",
    bip44: { coinType: 118 },
    bech32Config: Bech32Address.defaultBech32Config("osmo"),
    currencies: [
      {
        coinDenom: "OSMO",
        coinMinimalDenom: "uosmo",
        coinDecimals: 6,
        coinGeckoId: "osmosis",
      },
      {
        coinDenom: "ION",
        coinMinimalDenom: "uion",
        coinDecimals: 6,
        coinGeckoId: "ion",
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "OSMO",
        coinMinimalDenom: "uosmo",
        coinDecimals: 6,
        coinGeckoId: "osmosis",
      },
    ],
    gasPriceStep: {
      low: 0,
      average: 0.025,
      high: 0.035,
    },
    features: ["stargate", "ibc-transfer"],
  },
  // {
  //   rpc: "https://rpc-evmos.keplr.app",
  //   rest: "https://lcd-evmos.keplr.app",
  //   evm: {
  //     chainId: 9001,
  //     rpc: "https://evm-evmos.keplr.app",
  //   },
  //   chainId: "evmos_9001-2",
  //   chainName: "Evmos",
  //   stakeCurrency: {
  //     coinDenom: "EVMOS",
  //     coinMinimalDenom: "aevmos",
  //     coinDecimals: 18,
  //     coinGeckoId: "evmos",
  //   },
  //   walletUrl: "https://wallet.keplr.app/chains/evmos",
  //   walletUrlForStaking: "https://wallet.keplr.app/chains/evmos",
  //   bip44: {
  //     coinType: 60,
  //   },
  //   bech32Config: Bech32Address.defaultBech32Config("evmos"),
  //   currencies: [
  //     {
  //       coinDenom: "EVMOS",
  //       coinMinimalDenom: "aevmos",
  //       coinDecimals: 18,
  //       coinGeckoId: "evmos",
  //     },
  //   ],
  //   feeCurrencies: [
  //     {
  //       coinDenom: "EVMOS",
  //       coinMinimalDenom: "aevmos",
  //       coinDecimals: 18,
  //       coinGeckoId: "evmos",
  //       gasPriceStep: {
  //         low: 25000000000,
  //         average: 25000000000,
  //         high: 40000000000,
  //       },
  //     },
  //   ],
  //   features: ["ibc-transfer", "ibc-go", "eth-address-gen", "eth-key-sign"],
  // },
];
