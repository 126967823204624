/* eslint-disable */
import { grpc } from "@injectivelabs/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
export const protobufPackage = "injective_oracle_rpc";
function createBaseOracleListRequest() {
    return {};
}
export const OracleListRequest = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOracleListRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return OracleListRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(_) {
        const message = createBaseOracleListRequest();
        return message;
    },
};
function createBaseOracleListResponse() {
    return { oracles: [] };
}
export const OracleListResponse = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.oracles) {
            Oracle.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOracleListResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.oracles.push(Oracle.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { oracles: Array.isArray(object === null || object === void 0 ? void 0 : object.oracles) ? object.oracles.map((e) => Oracle.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.oracles) {
            obj.oracles = message.oracles.map((e) => e ? Oracle.toJSON(e) : undefined);
        }
        else {
            obj.oracles = [];
        }
        return obj;
    },
    create(base) {
        return OracleListResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a;
        const message = createBaseOracleListResponse();
        message.oracles = ((_a = object.oracles) === null || _a === void 0 ? void 0 : _a.map((e) => Oracle.fromPartial(e))) || [];
        return message;
    },
};
function createBaseOracle() {
    return { symbol: "", baseSymbol: "", quoteSymbol: "", oracleType: "", price: "" };
}
export const Oracle = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.symbol !== "") {
            writer.uint32(10).string(message.symbol);
        }
        if (message.baseSymbol !== "") {
            writer.uint32(18).string(message.baseSymbol);
        }
        if (message.quoteSymbol !== "") {
            writer.uint32(26).string(message.quoteSymbol);
        }
        if (message.oracleType !== "") {
            writer.uint32(34).string(message.oracleType);
        }
        if (message.price !== "") {
            writer.uint32(42).string(message.price);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOracle();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.symbol = reader.string();
                    break;
                case 2:
                    message.baseSymbol = reader.string();
                    break;
                case 3:
                    message.quoteSymbol = reader.string();
                    break;
                case 4:
                    message.oracleType = reader.string();
                    break;
                case 5:
                    message.price = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            symbol: isSet(object.symbol) ? String(object.symbol) : "",
            baseSymbol: isSet(object.baseSymbol) ? String(object.baseSymbol) : "",
            quoteSymbol: isSet(object.quoteSymbol) ? String(object.quoteSymbol) : "",
            oracleType: isSet(object.oracleType) ? String(object.oracleType) : "",
            price: isSet(object.price) ? String(object.price) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.symbol !== undefined && (obj.symbol = message.symbol);
        message.baseSymbol !== undefined && (obj.baseSymbol = message.baseSymbol);
        message.quoteSymbol !== undefined && (obj.quoteSymbol = message.quoteSymbol);
        message.oracleType !== undefined && (obj.oracleType = message.oracleType);
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
    create(base) {
        return Oracle.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e;
        const message = createBaseOracle();
        message.symbol = (_a = object.symbol) !== null && _a !== void 0 ? _a : "";
        message.baseSymbol = (_b = object.baseSymbol) !== null && _b !== void 0 ? _b : "";
        message.quoteSymbol = (_c = object.quoteSymbol) !== null && _c !== void 0 ? _c : "";
        message.oracleType = (_d = object.oracleType) !== null && _d !== void 0 ? _d : "";
        message.price = (_e = object.price) !== null && _e !== void 0 ? _e : "";
        return message;
    },
};
function createBasePriceRequest() {
    return { baseSymbol: "", quoteSymbol: "", oracleType: "", oracleScaleFactor: 0 };
}
export const PriceRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.baseSymbol !== "") {
            writer.uint32(10).string(message.baseSymbol);
        }
        if (message.quoteSymbol !== "") {
            writer.uint32(18).string(message.quoteSymbol);
        }
        if (message.oracleType !== "") {
            writer.uint32(26).string(message.oracleType);
        }
        if (message.oracleScaleFactor !== 0) {
            writer.uint32(32).uint32(message.oracleScaleFactor);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.baseSymbol = reader.string();
                    break;
                case 2:
                    message.quoteSymbol = reader.string();
                    break;
                case 3:
                    message.oracleType = reader.string();
                    break;
                case 4:
                    message.oracleScaleFactor = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            baseSymbol: isSet(object.baseSymbol) ? String(object.baseSymbol) : "",
            quoteSymbol: isSet(object.quoteSymbol) ? String(object.quoteSymbol) : "",
            oracleType: isSet(object.oracleType) ? String(object.oracleType) : "",
            oracleScaleFactor: isSet(object.oracleScaleFactor) ? Number(object.oracleScaleFactor) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.baseSymbol !== undefined && (obj.baseSymbol = message.baseSymbol);
        message.quoteSymbol !== undefined && (obj.quoteSymbol = message.quoteSymbol);
        message.oracleType !== undefined && (obj.oracleType = message.oracleType);
        message.oracleScaleFactor !== undefined && (obj.oracleScaleFactor = Math.round(message.oracleScaleFactor));
        return obj;
    },
    create(base) {
        return PriceRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d;
        const message = createBasePriceRequest();
        message.baseSymbol = (_a = object.baseSymbol) !== null && _a !== void 0 ? _a : "";
        message.quoteSymbol = (_b = object.quoteSymbol) !== null && _b !== void 0 ? _b : "";
        message.oracleType = (_c = object.oracleType) !== null && _c !== void 0 ? _c : "";
        message.oracleScaleFactor = (_d = object.oracleScaleFactor) !== null && _d !== void 0 ? _d : 0;
        return message;
    },
};
function createBasePriceResponse() {
    return { price: "" };
}
export const PriceResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePriceResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { price: isSet(object.price) ? String(object.price) : "" };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
    create(base) {
        return PriceResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a;
        const message = createBasePriceResponse();
        message.price = (_a = object.price) !== null && _a !== void 0 ? _a : "";
        return message;
    },
};
function createBaseStreamPricesRequest() {
    return { baseSymbol: "", quoteSymbol: "", oracleType: "" };
}
export const StreamPricesRequest = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.baseSymbol !== "") {
            writer.uint32(10).string(message.baseSymbol);
        }
        if (message.quoteSymbol !== "") {
            writer.uint32(18).string(message.quoteSymbol);
        }
        if (message.oracleType !== "") {
            writer.uint32(26).string(message.oracleType);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamPricesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.baseSymbol = reader.string();
                    break;
                case 2:
                    message.quoteSymbol = reader.string();
                    break;
                case 3:
                    message.oracleType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            baseSymbol: isSet(object.baseSymbol) ? String(object.baseSymbol) : "",
            quoteSymbol: isSet(object.quoteSymbol) ? String(object.quoteSymbol) : "",
            oracleType: isSet(object.oracleType) ? String(object.oracleType) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.baseSymbol !== undefined && (obj.baseSymbol = message.baseSymbol);
        message.quoteSymbol !== undefined && (obj.quoteSymbol = message.quoteSymbol);
        message.oracleType !== undefined && (obj.oracleType = message.oracleType);
        return obj;
    },
    create(base) {
        return StreamPricesRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c;
        const message = createBaseStreamPricesRequest();
        message.baseSymbol = (_a = object.baseSymbol) !== null && _a !== void 0 ? _a : "";
        message.quoteSymbol = (_b = object.quoteSymbol) !== null && _b !== void 0 ? _b : "";
        message.oracleType = (_c = object.oracleType) !== null && _c !== void 0 ? _c : "";
        return message;
    },
};
function createBaseStreamPricesResponse() {
    return { price: "", timestamp: "0" };
}
export const StreamPricesResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.timestamp !== "0") {
            writer.uint32(16).sint64(message.timestamp);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamPricesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.timestamp = longToString(reader.sint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    create(base) {
        return StreamPricesResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b;
        const message = createBaseStreamPricesResponse();
        message.price = (_a = object.price) !== null && _a !== void 0 ? _a : "";
        message.timestamp = (_b = object.timestamp) !== null && _b !== void 0 ? _b : "0";
        return message;
    },
};
function createBaseStreamPricesByMarketsRequest() {
    return { marketIds: [] };
}
export const StreamPricesByMarketsRequest = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.marketIds) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamPricesByMarketsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { marketIds: Array.isArray(object === null || object === void 0 ? void 0 : object.marketIds) ? object.marketIds.map((e) => String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.marketIds) {
            obj.marketIds = message.marketIds.map((e) => e);
        }
        else {
            obj.marketIds = [];
        }
        return obj;
    },
    create(base) {
        return StreamPricesByMarketsRequest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a;
        const message = createBaseStreamPricesByMarketsRequest();
        message.marketIds = ((_a = object.marketIds) === null || _a === void 0 ? void 0 : _a.map((e) => e)) || [];
        return message;
    },
};
function createBaseStreamPricesByMarketsResponse() {
    return { price: "", timestamp: "0", marketId: "" };
}
export const StreamPricesByMarketsResponse = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.price !== "") {
            writer.uint32(10).string(message.price);
        }
        if (message.timestamp !== "0") {
            writer.uint32(16).sint64(message.timestamp);
        }
        if (message.marketId !== "") {
            writer.uint32(26).string(message.marketId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStreamPricesByMarketsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.price = reader.string();
                    break;
                case 2:
                    message.timestamp = longToString(reader.sint64());
                    break;
                case 3:
                    message.marketId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            price: isSet(object.price) ? String(object.price) : "",
            timestamp: isSet(object.timestamp) ? String(object.timestamp) : "0",
            marketId: isSet(object.marketId) ? String(object.marketId) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.price !== undefined && (obj.price = message.price);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        message.marketId !== undefined && (obj.marketId = message.marketId);
        return obj;
    },
    create(base) {
        return StreamPricesByMarketsResponse.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c;
        const message = createBaseStreamPricesByMarketsResponse();
        message.price = (_a = object.price) !== null && _a !== void 0 ? _a : "";
        message.timestamp = (_b = object.timestamp) !== null && _b !== void 0 ? _b : "0";
        message.marketId = (_c = object.marketId) !== null && _c !== void 0 ? _c : "";
        return message;
    },
};
export class InjectiveOracleRPCClientImpl {
    constructor(rpc) {
        this.rpc = rpc;
        this.OracleList = this.OracleList.bind(this);
        this.Price = this.Price.bind(this);
        this.StreamPrices = this.StreamPrices.bind(this);
        this.StreamPricesByMarkets = this.StreamPricesByMarkets.bind(this);
    }
    OracleList(request, metadata) {
        return this.rpc.unary(InjectiveOracleRPCOracleListDesc, OracleListRequest.fromPartial(request), metadata);
    }
    Price(request, metadata) {
        return this.rpc.unary(InjectiveOracleRPCPriceDesc, PriceRequest.fromPartial(request), metadata);
    }
    StreamPrices(request, metadata) {
        return this.rpc.invoke(InjectiveOracleRPCStreamPricesDesc, StreamPricesRequest.fromPartial(request), metadata);
    }
    StreamPricesByMarkets(request, metadata) {
        return this.rpc.invoke(InjectiveOracleRPCStreamPricesByMarketsDesc, StreamPricesByMarketsRequest.fromPartial(request), metadata);
    }
}
export const InjectiveOracleRPCDesc = { serviceName: "injective_oracle_rpc.InjectiveOracleRPC" };
export const InjectiveOracleRPCOracleListDesc = {
    methodName: "OracleList",
    service: InjectiveOracleRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return OracleListRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = OracleListResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export const InjectiveOracleRPCPriceDesc = {
    methodName: "Price",
    service: InjectiveOracleRPCDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PriceRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = PriceResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export const InjectiveOracleRPCStreamPricesDesc = {
    methodName: "StreamPrices",
    service: InjectiveOracleRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamPricesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamPricesResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export const InjectiveOracleRPCStreamPricesByMarketsDesc = {
    methodName: "StreamPricesByMarkets",
    service: InjectiveOracleRPCDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
        serializeBinary() {
            return StreamPricesByMarketsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary(data) {
            const value = StreamPricesByMarketsResponse.decode(data);
            return Object.assign(Object.assign({}, value), { toObject() {
                    return value;
                } });
        },
    },
};
export class GrpcWebImpl {
    constructor(host, options) {
        this.host = host;
        this.options = options;
    }
    unary(methodDesc, _request, metadata) {
        var _a;
        const request = Object.assign(Object.assign({}, _request), methodDesc.requestType);
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders(Object.assign(Object.assign({}, (_a = this.options) === null || _a === void 0 ? void 0 : _a.metadata.headersMap), metadata === null || metadata === void 0 ? void 0 : metadata.headersMap))
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message.toObject());
                    }
                    else {
                        const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
                        reject(err);
                    }
                },
            });
        });
    }
    invoke(methodDesc, _request, metadata) {
        var _a;
        const upStreamCodes = this.options.upStreamRetryCodes || [];
        const DEFAULT_TIMEOUT_TIME = 3000;
        const request = Object.assign(Object.assign({}, _request), methodDesc.requestType);
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders(Object.assign(Object.assign({}, (_a = this.options) === null || _a === void 0 ? void 0 : _a.metadata.headersMap), metadata === null || metadata === void 0 ? void 0 : metadata.headersMap))
            : metadata || this.options.metadata;
        return new Observable((observer) => {
            const upStream = (() => {
                const client = grpc.invoke(methodDesc, {
                    host: this.host,
                    request,
                    transport: this.options.streamingTransport || this.options.transport,
                    metadata: maybeCombinedMetadata,
                    debug: this.options.debug,
                    onMessage: (next) => observer.next(next),
                    onEnd: (code, message, trailers) => {
                        if (code === 0) {
                            observer.complete();
                        }
                        else if (upStreamCodes.includes(code)) {
                            setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
                        }
                        else {
                            const err = new Error(message);
                            err.code = code;
                            err.metadata = trailers;
                            observer.error(err);
                        }
                    },
                });
                observer.add(() => client.close());
            });
            upStream();
        }).pipe(share());
    }
}
var tsProtoGlobalThis = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
export class GrpcWebError extends tsProtoGlobalThis.Error {
    constructor(message, code, metadata) {
        super(message);
        this.code = code;
        this.metadata = metadata;
    }
}
