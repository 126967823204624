{
    "name": "@tatumio/tatum",
    "version": "4.2.42",
    "description": "Tatum JS SDK",
    "author": "Tatum",
    "repository": "https://github.com/tatumio/tatum-js",
    "files": [
        "dist/"
    ],
    "license": "MIT",
    "keywords": [
        "Tatum",
        "SDK",
        "Blockchain"
    ],
    "engines": {
        "node": ">=18.0.0"
    },
    "main": "./dist/src/main.js",
    "types": "./dist/src/main.d.ts",
    "scripts": {
        "cli": "ts-node src/cli.ts",
        "lint": "eslint --fix src/ --ext .js,.jsx,.ts,.tsx && prettier --write src/",
        "test": "jest --forceExit",
        "clean": "rm -rf dist build package",
        "ts-node": "ts-node",
        "build": "tsc -p tsconfig.json",
        "build-all": "yarn clean && yarn build",
        "pack-all": "yarn build-all && yarn pack"
    },
    "dependencies": {
        "bignumber.js": "^9.1.1",
        "chalk": "^4.1.2",
        "reflect-metadata": "^0.1.13",
        "typedi": "^0.10.0"
    },
    "devDependencies": {
        "@types/jest": "^29.5.5",
        "@types/node": "^18.15.11",
        "@types/uuid": "^9.0.1",
        "@typescript-eslint/eslint-plugin": "^5.20.0",
        "@typescript-eslint/parser": "^5.20.0",
        "dotenv": "^16.0.3",
        "eslint": "^8.14.0",
        "jest": "^29.7.0",
        "jest-junit": "^16.0.0",
        "prettier": "^2.8.4",
        "prettier-plugin-organize-imports": "^3.2.2",
        "ts-jest": "^29.1.1",
        "ts-node": "^10.7.0",
        "tslib": "^2.5.0",
        "typescript": "^5.0.4",
        "undici": "^5.28.3"
    }
}
