import { CoinPrimitive } from "../../../common";

export type Balances = {
  balances: CoinPrimitive[];
  // TODO: Handle pagination?
  // pagination: {};
};

export type SpendableBalances = {
  balances: CoinPrimitive[];
  // TODO: Handle pagination?
  // pagination: {};
};
